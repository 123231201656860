import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  quizzesGroups : [],
}

// getters
const getters = {
  quizzesGroups: state => (quizId) => state.quizzesGroups.filter(s => s.quiz_id === quizId),
  quizzesGroupById: state => (groupsId) => state.quizzesGroups.find(s => s.id === groupsId  ),
}

// actions
const actions = {}

// mutations
const mutations = {
  [types.UPDATE_QUIZZES_GROUPS](state, groups) {
    groups.forEach(
      group => VuexHelpers.createOrUpdate(state.quizzesGroups, group)
    )
  },
  [types.DELETE_QUIZZES_GROUP](state, groupId) {
    state.quizzesGroups = state.quizzesGroups.filter(s => s.id !== groupId)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
