import * as types from "../../mutation-types"

// initial state
const state = {
  proctoring: false,
  showCamera: false,
}

// getters
const getters = {
  proctoring: state => state.proctoring,
  showCamera: state => state.showCamera,
}

// actions
const actions = {
  setProctoring({ commit }, value) {
    commit(types.UPDATE_PROCTORING_STATE, value)
  },
  setShowCamera({ commit }, value) {
    commit(types.UPDATE_SHOW_CAMERA_STATE, value)
  },
}

// mutations
const mutations = {
  [types.UPDATE_PROCTORING_STATE](state, value){
    state.proctoring = value
  },
  [types.UPDATE_SHOW_CAMERA_STATE](state, value){
    state.showCamera = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
