import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  studentScreenshots: [],
}

// getters
const getters = {
  studentScreenshots: state => (scoreId) => {
    return state.studentScreenshots.filter(s => s.score_id === scoreId)
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_STUDENT_SCREENSHOT]({ studentScreenshots }, studentScreenshot) {
    VuexHelpers.createOrUpdate(studentScreenshots, studentScreenshot)
  },
  [types.CREATE_OR_UPDATE_STUDENT_SCREENSHOTS]({ studentScreenshots }, newStudentScreenshots) {
    newStudentScreenshots.forEach(
      studentScreenshot => VuexHelpers.createOrUpdate(studentScreenshots, studentScreenshot)
    )
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
