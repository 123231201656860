import { computed } from "vue"
import { useStore } from "@/store"
import vueI18n from "@/plugins/i18n"

const nullObject = {
  name: vueI18n.t("quiz.settings.durationSettings.accessibilitySettings.timeAccommodation.noAccommodationName"),
  id: null,
  nominator: 0,
  denominator: 1,
}
export function useTimeAccommodation(timeAccommodation = nullObject) {
  const store = useStore()


  function timerExtraTimeMs(initialDurationMs) {
    return initialDurationMs * timeAccommodation.nominator / timeAccommodation.denominator
  }
  function timerDurationMs(initialDurationMs) {
    return initialDurationMs + timerExtraTimeMs(initialDurationMs)
  }

  function stopDateWithAccommodation(initialEndDate, initialDurationMs) {
    return new Date(initialEndDate.getTime() + timerExtraTimeMs(initialDurationMs))
  }

  const extraRatio = computed(() => timeAccommodation.nominator / timeAccommodation.denominator)

  /**
   * Return the schedule for a quiz for this time accommodation.
   *
   * @param {Object} quizDetails - An object containing the initial end date and duration of the quiz.
   * @param {Date} quizDetails.initialEndDate - The initial end date and time of the quiz.
   * @param {number} quizDetails.initialDurationMs - The initial duration of the quiz in milliseconds.
   * @returns {Object} An object containing the new end date, duration, and extra time for the quiz based on the time accommodation.
   */
  function scheduleForQuiz({ initialEndDate, initialDurationMs }) {
    return {
      stopDate: stopDateWithAccommodation(initialEndDate, initialDurationMs),
      timerDurationMs: timerDurationMs(initialDurationMs),
      timerExtraTimeMs: timerExtraTimeMs(initialDurationMs),
    }
  }

  function studentsCountInQuiz(quiz) {
    return store.getters.timeAccommodationSummary(timeAccommodation?.id, quiz.id, "Quiz")?.count || 0
  }

  function studentsCountInSession(session) {
    return store.getters.timeAccommodationSummary(timeAccommodation?.id, session.id, "Quizzes::Session")?.count || 0
  }

  return {
    timerDurationMs,
    stopDateWithAccommodation,
    timerExtraTimeMs,
    scheduleForQuiz,
    extraRatio,
    studentsCountInQuiz,
    studentsCountInSession,
    nullObject,
  }
}
