import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "@/helpers/json_apii"
import api from "@/api"


// initial state
const state = {
  publicSessions: [],
}

// getters
const getters = {
  quizPublicSessionByPublicToken: state => (publicToken) => {
    return state.publicSessions.find(e => e.public_token === publicToken)
  },
}

// actions
const actions = {
  fetchPublicSession({ commit }, slug){
    return axios.get(api.v1.quizzesPublicSessionUrl(slug)).then( response => {
      commit(types.CREATE_OR_UPDATE_PUBLIC_QUIZ_SESSION, jsonApii.getData(response.data))
      commit(types.UPDATE_QUIZZES, jsonApii.getIncluded(response.data, "quiz"))
      jsonApii.getIncluded(response.data, "school")
        .forEach(school => commit(types.UPDATE_SCHOOL, school))
    })
  },
}
// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_PUBLIC_QUIZ_SESSION]({ publicSessions }, publicSession){
    VuexHelpers.createOrUpdate(publicSessions, publicSession)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
