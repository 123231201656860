import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"
import documentModel from "./documents/document_model"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"

// initial state
const state = {
  documents: [],
}

// getters
const getters = {
  quizzesDocumentsByQuizId: state => quizId =>  {
    return state.documents
      .filter(d => d.quiz_id === quizId)
      .map(d => new documentModel.QuizDocument(d))
  },
}

// actions
const actions = {
  fetchDocumentsByQuiz({ commit, dispatch }, quiz) {
    axios
      .get(quiz.links.documents)
      .then( response => {
        commit(types.UPDATE_QUIZZES_DOCUMENTS, jsonApii.getData(response.data))
      })
      .catch()
  },

  updateQuizzesDocument({ commit, dispatch }, document) {
    axios
      .patch(document.links.self, document)
      .then( response => {
        commit(types.UPDATE_QUIZZES_DOCUMENT, jsonApii.getData(response.data))
      })
      .catch()
  },

  deleteQuizzesDocument({ commit, dispatch }, document) {
    axios
      .delete(document.links.self)
      .then( response => {
        commit(types.DELETE_QUIZZES_DOCUMENT, document)
      })
      .catch()
  },
}

// mutations
const mutations = {
  [types.UPDATE_QUIZZES_DOCUMENT](state, document){
    VuexHelpers.createOrUpdate(state.documents, document)
  },
  [types.UPDATE_QUIZZES_DOCUMENTS](state, documents){
    state.documents = documents
  },
  [types.DELETE_QUIZZES_DOCUMENT]({ documents }, document){
    let index = documents.findIndex(e => e.id === document.id)
    documents.splice(index, 1)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
