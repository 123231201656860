import instanceNodesModels from "./instance_nodes_models"

// TODO: This function should be removed when paperQuizSheet will be merged with
// quizInstance in the backend
const  parseQuizSheet = (instance) => {
  const rootNode = instance?.quiz_data?.snapshot
  if(rootNode == null) return {}
  return instanceNodesModels.parseNodes([rootNode], instance.user_id)[0]
}

export default {
  parseQuizSheet,
}
