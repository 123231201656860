import axios from "axios"
import * as types from "../../../mutation-types"
import jsonApii from "../../../../helpers/json_apii"
import VuexHelpers from "../../../../helpers/vuex_helpers"


// initial state
const state = {
  channels: [],
}

// getters
const getters = {
  channelById: state => channelId => {
    return state.channels.find(channel => channel.id === channelId)
  },
  channels: state => quizId =>  {
    return state.channels.filter(c => c.quiz_id === quizId).sort((a, b) => {
      if (b.user_id && a.user_id)
        return new Date(a.updated_at) - new Date(b.updated_at)
    })
  },
  globalChannelByQuizId: state => quizId => {
    return state.channels.find(channel => channel.quiz_id === quizId && !channel.user_id)
  },
  unreadMessagesOnChannel: (state, rootGetters) => channel => {
    return channel.unread_messages.filter(message => message.user_id !== rootGetters.currentUser?.id)
  },
  allUnreadMessagesByQuizCount: (state, rootGetters) => quizId => {
    return state.channels.filter(channel => channel.quiz_id === quizId && channel.user_id)
      .reduce((acc, channel) => acc + rootGetters.unreadMessagesOnChannel(channel).length, 0)
  },
}

// actions
const actions = {
  fetchChannelsByQuiz(context, quiz) {
    return axios
      .get(quiz.links.channels)
      .then( response => {
        context.commit(types.UPDATE_CHAT_CHANNELS, jsonApii.getData(response.data))
        context.commit(types.UPDATE_CHAT_MESSAGES, jsonApii.getIncluded(response.data, "message"), { root: true })
      })
  },

  createChannelsForQuiz({ commit }, { quiz, userId }) {
    return axios
      .post(
        quiz.links.channels,
        {
          user_id: userId,
        }
      )
      .then( response => {
        commit(types.UPDATE_CHAT_CHANNEL, jsonApii.getData(response.data))
        return jsonApii.getData(response.data)
      })
      .catch()
  },
}

// mutations
const mutations = {
  [types.UPDATE_CHAT_CHANNELS](state, channels){
    state.channels = channels
  },

  [types.UPDATE_CHAT_CHANNEL](state, channels){
    VuexHelpers.createOrUpdate(state.channels, channels)
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
