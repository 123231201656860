// initial state
import * as types from "@/store/mutation-types"
import VuexHelpers from "@/helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "@/helpers/json_apii"
import { useTimeAccommodation } from "@/composables/useTimeAccommodation"

const state = {
  timeAccommodations : [],
}

// getters
const getters = {
  timeAccommodations: state => (school_id) => state.timeAccommodations.filter(ta => ta.school_id === school_id),
  timeAccommodation: (state) => (id) => {
    return state.timeAccommodations.find(ta => ta.id === id) || useTimeAccommodation().nullObject
  },
}

// actions
const actions = {
  fetchTimeAccommodations({ commit }, school) {
    return axios
      .get(school.links.time_accommodations)
      .then(response => {
        jsonApii.getData(response.data).forEach(timeAccommodation => {
          commit(types.CREATE_OR_UPDATE_TIME_ACCOMMODATION, timeAccommodation)
        })
      })
  },
  createTimeAccommodation({ commit }, { timeAccommodation, school }) {
    return axios.post(school.links.time_accommodations, timeAccommodation)
      .then(response => {
        commit(types.CREATE_OR_UPDATE_TIME_ACCOMMODATION, jsonApii.getData(response.data))
      })
  },
  updateTimeAccommodation({ commit }, timeAccommodation) {
    return axios.patch(timeAccommodation.links.self, timeAccommodation)
      .then(response => {
        commit(types.CREATE_OR_UPDATE_TIME_ACCOMMODATION, jsonApii.getData(response.data))
      })
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_TIME_ACCOMMODATION](state, timeAccommodation){
    VuexHelpers.createOrUpdate(state.timeAccommodations, timeAccommodation)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
