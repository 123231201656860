import Vue from "vue"

let env_value = import.meta.env.VITE_VUE_DEVTOOLS
if (env_value !== undefined) {
  Vue.config.devtools = (env_value === "true")
  console.debug(`VITE_VUE_DEVTOOLS is ${env_value}`)

} else {
  console.debug(`VITE_VUE_DEVTOOLS is not defined, default value : ${Vue.config.devtools}`)
}
