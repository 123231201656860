import * as types from "../mutation-types"

import { v1 as uuidv1 } from "uuid"


// initial state
const state = {
  appNotifications : [],
}

// getters
const getters = {
  appNotifications: state => state.appNotifications,
}

// actions
const actions = {
  newAppNotification({ commit }, { message, type, timeout } = { type: "success" }) {
    commit(types.CREATE_APP_NOTIFICATION,{ message, type, timeout })
  },
}

// mutations
const mutations = {
  [types.CREATE_APP_NOTIFICATION](state, { message, type, timeout }) {
    state.appNotifications.push({ message, type, timeout, show: true, uuid: uuidv1() })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
