import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "@/helpers/json_apii"


// initial state
const state = {
  sessions: [],
}

// getters
const getters = {
  quizSessions: state => (quizId) => {
    return state.sessions.filter(e => e.quiz_id === quizId)
  },
}

// actions
const actions = {
  createSessionForQuiz({ commit }, { quiz, session }){
    return axios.post(
      quiz.links.sessions,
      { session },
    ).then( response => {
      commit(types.CREATE_OR_UPDATE_QUIZ_SESSION, jsonApii.getData(response.data))
      jsonApii.getIncluded(response.data,"time_accommodation_summary").forEach( ta => {
        commit(types.CREATE_OR_UPDATE_TIME_ACCOMMODATION_SUMMARY, ta, { root: true })
      })
    })
  },
  updateSession({ commit }, session){
    return axios.patch(
      session.links.self,
      { session },
    ).then( response => {
      commit(types.CREATE_OR_UPDATE_QUIZ_SESSION, jsonApii.getData(response.data))
      jsonApii.getIncluded(response.data,"time_accommodation_summary").forEach( ta => {
        commit(types.CREATE_OR_UPDATE_TIME_ACCOMMODATION_SUMMARY, ta, { root: true })
      })
    })
  },
  sendConvocation({ commit }, { session, onlySubscriptionIds } = { subscriptionIds: null }){
    return axios.post(
      session.links.send_convocations,
      { only_subscription_ids: onlySubscriptionIds }

    ).then( response => {
      commit(types.CREATE_OR_UPDATE_QUIZ_SESSION, jsonApii.getData(response.data))
    })
  },
  openSession({ commit }, session){
    return axios.post(
      session.links.open,
    ).then( response => {
      commit(types.CREATE_OR_UPDATE_QUIZ_SESSION, jsonApii.getData(response.data))
    })
  },
  closeSession({ commit }, session){
    return axios.post(
      session.links.close,
    ).then( response => {
      commit(types.CREATE_OR_UPDATE_QUIZ_SESSION, jsonApii.getData(response.data))
    })
  },
  destroySession({ commit }, session){
    return axios.delete(
      session.links.self,
    ).then( () => {
      commit(types.DELETE_QUIZ_SESSION, session)
    })
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_QUIZ_SESSION]({ sessions }, session){
    VuexHelpers.createOrUpdate(sessions, session)
  },
  [types.CREATE_OR_UPDATE_QUIZ_SESSIONS]({ sessions }, newSessions){
    newSessions.forEach(
      newSession => VuexHelpers.createOrUpdate(sessions, newSession)
    )
  },
  [types.DELETE_QUIZ_SESSION]({ sessions }, session){
    const index = sessions.findIndex(e => e.id === session.id)
    sessions.splice(index, 1)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
