import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  similarityCheckSubmissions: [],
}

// getters
const getters = {
  similarityCheckSubmissionsByAnswer: state => answerId =>  {
    return state.similarityCheckSubmissions.find(s => s.answer_id === answerId)
  },
}

// actions
const actions = {

}

// mutations
const mutations = {
  [types.UPDATE_SIMILARITY_CHECK_SUBMISSIONS](state, similarityCheckSubmissions){
    similarityCheckSubmissions.forEach(similarityCheckSubmission => {
      VuexHelpers.createOrUpdate(state.similarityCheckSubmissions, similarityCheckSubmission)
    })
  },
  [types.UPDATE_SIMILARITY_CHECK_SUBMISSION](state, similarityCheckSubmission){
    VuexHelpers.createOrUpdate(state.similarityCheckSubmissions, similarityCheckSubmission)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
