import * as types from "../../mutation-types"
import axios from "axios"
import api from "../../../api"
import jsonApii from "../../../helpers/json_apii"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  quizzesTags: [],
}

// getters
const getters = {
  quizzesTags: (state, rootGetters) => state.quizzesTags.filter(t => {
    const schoolsUserOwner = rootGetters.schoolsUser(t.schools_user_id)

    return rootGetters.currentSchool?.id === schoolsUserOwner?.school_id
  }),
  quizzesTagsByIds: (state, getters) => (ids) => ids.map(t_id => getters.quizzesTag(t_id)),
  quizzesTag: state => id => state.quizzesTags.find(t => t.id === id),
  quizzesTagsWithoutParents: (_state, getters) => getters.quizzesTags.filter(t => t.parent_id == null),
  quizzesTagsChildren: (_state, getters) => tag => {
    let children = getters.quizzesTags.filter(t => t.parent_id === tag.id)
    return getters.tagsTree(tag.quiz_type, children)
  },
  tagsTree: (state, getters) => (quizType = "", parentTag = getters.quizzesTagsWithoutParents)  =>{
    return parentTag
      .filter(t => t.quiz_type === quizType )
      .map( t => ({
        ...t,
        children: getters.quizzesTagsChildren(t),
      }))
  },
  lastTagChild: (state, getters) => tags => {
    if (tags.length === 0) return null

    let tagParentIds = tags.map(t => t?.parent_id)

    return tags.find( t => !tagParentIds.includes(t?.id))
  },
  tagsWithParents: (state, getters) => _tags => {

    if(_tags.length === 0) return []
    let tags = _tags
    let tag = tags?.slice(-1)[0]
    let parent = getters.quizzesTag(tag?.parent_id)
    if (tag?.parent_id) {
      tags.push(parent)
      return getters.tagsWithParents(tags)
    } else {
      return tags
    }
  },
  orderedTagsByParent: (state, getters) => tagIds => {
    if(tagIds.length === 0 ) return []
    return getters.tagsWithParents(
      [getters.lastTagChild(getters.quizzesTagsByIds(tagIds))]
    ).reverse()
  },
}

// actions
const actions = {
  fetchTags({ commit }) {
    axios
      .get(api.v1.tagsUrl())
      .then( response => {
        commit(types.UPDATE_QUIZZES_TAGS,jsonApii.getData(response.data))
      })
  },
  updateTag(context, tag) {
    axios
      .patch(tag.links.self, tag)
      .then( response => {
        context.commit(types.CREATE_OR_UPDATE_TAG, jsonApii.getData(response.data))
        context.dispatch("fetchOwnedQuizzes", { filters: { filter: { quiz_type: "exam" } } })
      })
  },
  createTag({ commit, rootGetters }, tag) {
    axios
      .post(
        api.v1.tagsUrl(),
        {
          ...tag,
          schools_user_id: rootGetters.currentSchoolsUser.id,
        })
      .then( response => {
        commit(types.CREATE_OR_UPDATE_TAG, jsonApii.getData(response.data))
      })
  },
  deleteTag(context, tag) {
    axios
      .delete(tag.links.self)
      .then( response => { context.commit(types.DELETE_TAG, tag) })
      .catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Erreur lors de la suppression du tag " + error, type: "error" }
        )
      })
  },
  flushTags({ commit }) {
    commit(types.DELETE_TAGS)
  },

}

// mutations
const mutations = {
  [types.UPDATE_QUIZZES_TAGS](state, tags){
    tags.forEach(
      tag => VuexHelpers.createOrUpdate(state.quizzesTags, tag)
    )
  },
  [types.CREATE_OR_UPDATE_TAG](state, tag){
    VuexHelpers.createOrUpdate(state.quizzesTags, tag)
  },
  [types.DELETE_TAG]({ quizzesTags }, tag){
    let index = quizzesTags.findIndex(e => e.id === tag.id)
    quizzesTags.splice(index, 1)
  },
  [types.DELETE_TAGS](state){
    state.quizzesTags = []
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
