import { BaseModel } from "./baseModel"

class Answer extends BaseModel{
  constructor(answer) {
    super()
    this.choices_ids = answer.choices_ids
    this.content = answer.content
    this.pictures = answer.pictures
    this.spreadsheet_content = answer.spreadsheet_content
    this.links = answer.links
    this.id = answer.id
  }

  get isSkipped() {
    return !this.isAnswered
  }

  get isAnswered() {
    return this.choices_ids?.length > 0 ||
      ![null, ""].includes(this.content) ||
      this.pictures?.length > 0 ||
      this.spreadsheet_content !== {}
  }
}

export { Answer }
