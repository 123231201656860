import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"
import quizSheetNodesModels from "./instances/quiz_sheet_nodes_models"

// initial state
const state = {
  paperSheets: [],
}

// getters
const getters = {
  paperSheetContantByQuizIdAndUserId: state => (quizId, userId) =>  {
    return quizSheetNodesModels.parseQuizSheet(
      state.paperSheets
        .find(p => p.quiz_id === quizId && p.user_id === userId)

    )
  },
}

// actions
const actions = {
  // Dorian - 2024-10-13
  // This fetcher does not make sense anymore are it return subjects initially
  // generated for a student for a nominative paper quiz.
  // It should be removed to fetch the subject (PaperQuizSheet) linked to
  // a scanned page (ScannedPaperQuizSheet) instead.
  fetchPaperSheet({ commit }, { quiz, subscriptionIds }) {
    return axios
      .get(
        quiz.links.paper_sheets,
        {
          params: {
            filter: {
              subscription_id: { eq: subscriptionIds },
            },
          },
          paramsSerializer: jsonApii.toFilters,
        })
      .then( response => {
        jsonApii.getData(response.data).forEach(paperSheet => {
          commit(types.UPDATE_PAPER_SHEET, paperSheet)
        })
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_PAPER_SHEET](state, paperSheet){
    VuexHelpers.createOrUpdate(state.paperSheets, paperSheet)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
