import * as types from "../../mutation-types"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  questionsTags: [],
}

// getters
const getters = {
  defaultQuestionsTags: state => state.defaultQuestionsTags,
  defaultQuestionsTagsCategory: state => state.defaultQuestionsTagsCategory,
  questionsTags: state => state.questionsTags,
  questionsTagsByCategories: state => categories => {
    return state.questionsTags.filter(tag => categories.map(category => category.id).includes(tag.category_id))
  },
  questionsTagsByCategoryId: state => id => state.questionsTags.filter(tag => tag.category_id === id),
  questionsTagsByIds: state => ids => state.questionsTags.filter(tag => ids.includes(tag.id)),
  questionsTagByUuid: state => uuid => {
    return state.questionsTags.find(questionTag => questionTag.uuid === uuid)
  },
}

// actions
const actions = {
  updateQuestionsTag(context, tag) {
    axios
      .patch(tag.links.self, tag)
      .then( response => {
        context.commit(types.CREATE_OR_UPDATE_QUESTION_TAG, jsonApii.getData(response.data))
      })
  },
  updateCategory(context, { tag: tag, category: category }) {
    axios
      .patch(tag.links.category, category)
      .then( response => {
        context.commit(types.CREATE_OR_UPDATE_QUESTION_TAG, jsonApii.getData(response.data))
      })
  },
  async createQuestionsTag({ commit }, { quiz: quiz, tag: tag }) {
    await axios
      .post(quiz.links.questions_tags, tag)
      .then( response => {
        commit(types.CREATE_OR_UPDATE_QUESTION_TAG, jsonApii.getData(response.data))
      })
  },
  createQuestionsTagsCategory({ commit }, { quiz, name }) {
    return axios
      .post(quiz.links.questions_tags_category, { name: name })
      .then( response => {
        commit(types.CREATE_OR_UPDATE_QUESTION_TAGS_CATEGORY, jsonApii.getData(response.data))
        return jsonApii.getData(response.data)
      })
  },
  deleteQuestionsTag(context, tag) {
    axios
      .delete(tag.links.self)
      .then( () => { context.commit(types.DELETE_QUESTION_TAG, tag) })
      .catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Erreur lors de la suppression du tag " + error, type: "error" }
        )
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_QUESTIONS_TAGS](state, tags){
    tags.forEach(
      tag => VuexHelpers.createOrUpdate(state.questionsTags, tag)
    )
  },
  [types.CREATE_OR_UPDATE_QUESTION_TAG](state, tag){
    VuexHelpers.createOrUpdate(state.questionsTags, tag)
  },
  [types.DELETE_QUESTION_TAG]({ questionsTags }, tag){
    let index = questionsTags.findIndex(e => e.id === tag.id)
    questionsTags.splice(index, 1)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
