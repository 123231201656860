import { BaseQuestionBehavior } from "./base_question_behavior"
import vueI18n from "../../../plugins/i18n"

class OpenBehavior extends BaseQuestionBehavior {

  get canHaveExpectedAnswer() { return true }


  subtitleInstructions() {
    return vueI18n.t("live.enter")
  }
}

export { OpenBehavior }
