import { BaseQuestionBehavior } from "./base_question_behavior"
import draftNodes from "../../../store/modules/quizzes/draft/draft_nodes"
import vueI18n from "../../../plugins/i18n"

class McqBehavior extends BaseQuestionBehavior {

  /**
   * @return {Array<ChoiceDraft>}
   */
  defaultChildren() {
    return [
      new draftNodes.ChoiceDraft({
        correct: true,
        name:  vueI18n.t("evalmee.home.teacher.quizEditor.choice.defaultName", { index: "1" }),
      }),
      new draftNodes.ChoiceDraft({
        name:  vueI18n.t("evalmee.home.teacher.quizEditor.choice.defaultName", { index: "2" }),
      }),
    ]
  }

  hasEnoughCorrectChoices() {
    return this.questionNode.children.some(choice => choice?.attributes?.correct)
  }

  hasEnoughChoices() {
    return this.questionNode.children.length > 1
  }

  /**
   * @returns {number}
   */
  correctChoicesCount() {
    return this.questionNode.children.filter(choice => choice?.attributes?.correct).length
  }

  subtitleInstructions(
    { correctChoicesCount, withCount } = { withCount: false }
  ) {

    if(this.allowMultipleChoices) {
      if (withCount) {
        return vueI18n.t("live.nCorrectChoices", { n: correctChoicesCount })
      } else {
        return vueI18n.t("live.severalCorrectChoices")
      }
    }

    return vueI18n.t("live.oneCorrectChoice")
  }

  get allowMultipleChoices() {
    if( this.questionNode.children
      .filter(choice => choice?.attributes?.correct)
      .length > 1
    ) {
      return true
    }

    return this.questionNode.attributes.allow_multiple_choices
  }
}

export { McqBehavior }
