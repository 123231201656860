const jsonApii = {

  /**
   * @return {Array<Object>, Object}
   */
  getData(api_response){
    if (Array.isArray(api_response.data)) { return this.getDataCollection(api_response) }

    return Object.assign(
      api_response.data.attributes,
      { links: api_response.data.links }
    )
  },

  getDataCollection(api_response){
    return api_response.data.map(x => {
      return Object.assign(
        x.attributes,
        { links: x.links }
      )
    })
  },

  /**
   * @param {Object} resource Resource from VueX
   * @param {Object} error    Error object caught from Axios
   *
   * @return {Object}
   */
  parseErrors(resource, error) {
    return {
      ...resource,
      errors: error?.response?.data?.data?.attributes?.errors || [],
    }
  },

  getIncluded(api_response, resourceType){
    return api_response
      ?.included
      ?.filter(x => x.type === resourceType)
      ?.map(x => {
        return { ...x.attributes, links: x.links }
      }) || []
  },

  getMeta(api_response) {
    return api_response?.meta
  },

  // params: filters = {classroom_id: [1,2], quiz_type:'exam'}
  // returns: { 'filter[classroom_id]': '1,2', 'filter[quiz_type]': 'exam' }
  toFilters(filters) {
    // const removeUndefinedFields = (obj) => {
    //   let newObj = {}
    //   Object.keys(obj).forEach((key) => {
    //     if (obj[key] === Object(obj[key])) newObj[key] = removeUndefinedFields(obj[key])
    //     else if (obj[key] !== undefined) newObj[key] = obj[key]
    //   })
    //   return newObj
    // }

    let getPairs = (obj, keys = []) =>
      Object.entries(obj).reduce((pairs, [key, value]) => {
        if (Array.isArray(value)) {
          pairs.push([[...keys, key], value.join(",")])
        } else if (typeof value === "object") {
          pairs.push(...getPairs(value, [...keys, key]))
        } else {
          pairs.push([[...keys, key], value])
        }
        return pairs
      }, [])

    return getPairs(JSON.parse(JSON.stringify(filters)))
      .map(([[root, ...nested], value]) =>
        `${root}${nested.map(a => `[${a}]`).join("")}=${value}`)
      .join("&")
  },

  nullToEmptyString(json) {
    for (let key in json) {
      if (json[key] === undefined || json[key] === null) {
        json[key] = ""
      } else if (typeof json[key] === "object")
        json[key] = this.nullToEmptyString(json[key])
    }
    return json
  },
}

export default jsonApii
