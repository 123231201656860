import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  scannedPapers: [],
  scansPaperLoading: true,
}

// getters
const getters = {
  scannedPapers: state => state.scannedPapers.sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at)
  }),
  scansPaperLoading: state => state.scansPaperLoading,
}

// actions
const actions = {
}

// mutations
const mutations = {
  [types.UPDATE_SCAN_PAPERS](state, scanPapers){
    state.scannedPapers = scanPapers
  },
  [types.UPDATE_SCAN_PAPER](state, scanPaper){
    VuexHelpers.createOrUpdate(state.scannedPapers, scanPaper)
  },
  [types.SCAN_PAPER_LOADING](state, status){
    state.scansPaperLoading = status
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
