import * as types from "../../../mutation-types"
import VuexHelpers from "../../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "../../../../helpers/json_apii"

// initial state
const state = {
  proctoringImagesAnalysis: [],
  proctoringImagesAnalysisLoading: false,
}

// getters
const getters = {
  proctoringImagesAnalysis: state =>  name => {
    return state.proctoringImagesAnalysis.find(x => x.image_name === name)
  },
  proctoringImagesAnalyses: state =>  (quizId, userId) => {
    return state
      .proctoringImagesAnalysis
      .filter(x => x.quiz_id === quizId, x => x.user_id === userId)
  },
  proctoringImagesAnalysisLoading: state => state.proctoringImagesAnalysisLoading,
}

// actions
const actions = {
  fetchProctoringImagesAnalysis(context, { quiz, user_id }) {
    context.commit(types.PROCTORING_IMAGES_LOADING, true)
    axios
      .get(`/api/v1/quizzes/${quiz.id}/proctoring_images_analysis?user_id=${user_id}`)
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS,
          jsonApii.getData(response.data)
        )
        context.commit(types.PROCTORING_IMAGES_ANALYSIS_LOADING, false)
      })
      .catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Images fetch error: " + error, type: "error" }
        )
        context.commit(types.PROCTORING_IMAGES_ANALYSIS_LOADING, false)
      })
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS](state, images){
    state.proctoringImagesAnalysis = images
  },
  [types.PROCTORING_IMAGES_ANALYSIS_LOADING](state, loading){
    state.proctoringImagesAnalysisLoading = loading
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
