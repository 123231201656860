// initial state
import * as types from "@/store/mutation-types"
import VuexHelpers from "@/helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "@/helpers/json_apii"

const state = {
  accessibilityOptions : [],
}

// getters
const getters = {
  accessibilityOptionBySchoolsUserId: state => (schoolsUserId) => {
    return state.accessibilityOptions.find(ao => ao.schools_user_id === schoolsUserId)
  },
}

// actions
const actions = {
  createAccessibilityOption({ commit }, { accessibilityOption, schoolsUser }) {
    return axios.post(
      schoolsUser.links.quizzes_accessibility_option,
      { quizzes_accessibility_option: accessibilityOption }
    ).then(response => {
      commit(types.CREATE_OR_UPDATE_ACCESSIBILITY_OPTION, jsonApii.getData(response.data))
    })
  },
  updateAccessibilityOption({ commit }, accessibilityOption) {
    return axios.patch(
      accessibilityOption.links.self,
      { quizzes_accessibility_option: accessibilityOption }
    ).then(response => {
      commit(types.CREATE_OR_UPDATE_ACCESSIBILITY_OPTION, jsonApii.getData(response.data))
    })
  },
  deleteAccessibilityOption({ commit }, accessibilityOption) {
    return axios.delete(accessibilityOption.links.self).then(() => {
      commit(types.DELETE_ACCESSIBILITY_OPTION, accessibilityOption)
    })
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_ACCESSIBILITY_OPTION](state, accessibilityOption){
    VuexHelpers.createOrUpdate(state.accessibilityOptions, accessibilityOption)
  },
  [types.DELETE_ACCESSIBILITY_OPTION](state, accessibilityOption){
    const index = state.accessibilityOptions.findIndex(e => e.id === accessibilityOption.id)
    state.accessibilityOptions.splice(index, 1)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
