const arrayHelpers = {
  orderBy(key, direction = "asc") {
    const val = direction === "asc" ? -1 : 1

    return (a, b) => (a[key] > b[key]) ? val : ((b[key] > a[key]) ? -val : 0)
  },

  difference(array1, array2) {
    return array1.filter(x => !array2.includes(x))
  },

  arrayRemove(arr, value) {
    return arr.filter(function(ele){
      return ele != value
    })
  },

  arrayGroupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  },

  arraysEqual(a,b) {
    /*
     Array-aware equality checker:
     Returns whether arguments a and b are == to each other;
     however if they are equal-lengthed arrays, returns whether their
     elements are pairwise == to each other recursively under this
     definition.
     */
    if (a instanceof Array && b instanceof Array) {
      if (a.length != b.length)  // assert same length
        return false
      for(var i = 0; i < a.length; i++)  // assert each element equal
        if (!this.arraysEqual(a[i],b[i]))
          return false
      return true
    } else {
      return a == b  // if not both arrays, should be the same
    }
  },

  /*
   * Returns only the unique elements of an array
   * usage: a.filter(onlyUnique)
   */
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  },

  /**
   * Return a new array of arrays containing consecutive duplicate elements
   * usage: splitByConsecutive(array)
   *
   * @param array Array
   * @param evaluator Function to test array items equality
   * @return Array
   */
  splitByConsecutive(array, evaluator = (prev, curr) => prev === curr) {
    let out = []
    let temps = []
    array.forEach((e,index) => {
      if (index === 0) {
        temps.push(e)
      } else if (index === array.length - 1) {
        out.push(temps)
      } else if ( !evaluator(e, array[index - 1]) ) {
        out.push(temps)
        temps = []
        temps.push(e)
      } else {
        temps.push(e)
      }
    })

    return out

  },

}

export default arrayHelpers
