import * as types from "../mutation-types"
import axios from "axios"
import api from "../../api"
import jsonApii from "../../helpers/json_apii"
import VuexHelpers from "../../helpers/vuex_helpers"

// initial state
const state = {
  schools : [],
  school : {},
}

// getters
const getters = {
  schools: state => state.schools,
  //@deprecated
  school: state => state.school,
  currentSchool: (state, getters, rootState, rootGetters) => {
    const currentUser = rootGetters.currentUser
    return state.schools.find(s => s.id === currentUser.last_school_id)
  },
  currentUserSchools: (state, getters, rootState, rootGetters) => {
    const currentSchoolsUsers = rootGetters.currentSchoolsUsers
    return state.schools.filter(s => currentSchoolsUsers.find(su => su.school_id === s.id))
  },
}

// actions
const actions = {
  fetchSchools({ commit },{ name }) {
    return axios
      .get(
        api.v1.schoolsUrl(),
        {
          params: {
            search: name,
          },
          paramsSerializer: jsonApii.toFilters,
        },
      )
      .then( responce => {
        jsonApii.getData(responce.data).forEach( school => {
          commit(types.UPDATE_SCHOOL, school)
        })
      })
  },
  fetchSchool({ commit }, id) {
    return axios.get(api.v1.schoolUrl(id))
      .then( response => {
        commit(types.UPDATE_SCHOOL, jsonApii.getData(response.data))
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_SCHOOL](state, school){
    VuexHelpers.createOrUpdate(state.schools, school)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
