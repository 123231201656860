import * as types from "../../mutation-types"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"
import VuexHelpers from "../../../helpers/vuex_helpers"


////////////////////////////////////////////////////
// Todo : merge this module with the questions one
// Check also the websocket file to for teacherRoom
///////////////////////////////////////////////////



// initial state
const state = {
  questionsStats: [],
  questionsStatsLoading: true,
}

// getters
const getters = {
  questionsStats: state => state.questionsStats,
  questionsStatsByQuizId: state => id => state.questionsStats.filter(q => q.quiz_id === id).sort((a, b) =>  a.id - b.id),
  questionsStatsLoading: state => state.questionsStatsLoading,
}

// actions
const actions = {
  fetchQuestionsStatsByQuiz(context, quiz) {
    if (!quiz?.links?.questions_stats) return
    const { commit } = context

    commit(types.UPDATE_QUESTIONS_STATS_LOADING, true)

    return axios
      .get(quiz.links.questions_stats)
      .then( response => {
        commit(types.UPDATE_QUESTIONS_STATS,jsonApii.getData(response.data))
        commit(types.UPDATE_QUESTIONS_STATS_LOADING, false)
      })
      .catch( error => {
        commit(types.UPDATE_QUESTIONS_STATS_LOADING, false)
        VuexHelpers.newAppNotification(
          context,
          { message: "Questions stats fetch error: " + error, type: "error" }
        )
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_QUESTION_STATS](state, question_stats){
    VuexHelpers.createOrUpdate(state.questionsStats, question_stats)
  },
  [types.UPDATE_QUESTIONS_STATS](state, questions_stats){
    state.questionsStats = questions_stats
  },
  [types.UPDATE_QUESTIONS_STATS_LOADING](state, status){
    state.questionsStatsLoading = status
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
