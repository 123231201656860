import * as types from "../../../mutation-types"
import VuexHelpers from "../../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "../../../../helpers/json_apii"

// initial state
const state = {
  proctoringImages: [],
  proctoringImagesLoading: false,
}

// getters
const getters = {
  proctoringImages: state => state.proctoringImages,
  proctoringImagesByQuizAndUserId: state => (quizId, userId) => (
    (state.proctoringImages || [])
      .filter(i => i.user_id == userId && i.quiz_id == quizId)
  ),
  proctoringImagesLoading: state => state.proctoringImagesLoading,
}

// actions
const actions = {
  fetchProctoringImages(context, { quiz, user_id }) {
    context.commit(types.PROCTORING_IMAGES_LOADING, true)
    axios
      .get(`/api/v1/quizzes/${quiz.id}/proctoring_images?user_id=${user_id}`)
      .then( response => {
        context.commit(types.CREATE_OR_UPDATE_PROCTORING_IMAGES, response.data)
        context.commit(types.PROCTORING_IMAGES_LOADING, false)
      })
      .catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Images fetch error: " + error, type: "error" }
        )
        context.commit(types.PROCTORING_IMAGES_LOADING, false)
      })
  },
  fetchLastProctoringImages(context, { quiz, user_id }) {
    context.commit(types.PROCTORING_IMAGES_LOADING, true)
    axios
      .get(`/api/v1/quizzes/${quiz.id}/proctoring_images/last?user_id=${user_id}`)
      .then( response => {
        context.commit(types.CREATE_OR_UPDATE_PROCTORING_IMAGE, response.data)
        context.commit(types.PROCTORING_IMAGES_LOADING, false)
      })
      .catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Images fetch error: " + error, type: "error" }
        )
        context.commit(types.PROCTORING_IMAGES_LOADING, false)
      })
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_PROCTORING_IMAGES](state, images){
    images.forEach(image => (
      VuexHelpers.createOrUpdate(state.proctoringImages, image, ["file_name"])
    ))
  },
  [types.CREATE_OR_UPDATE_PROCTORING_IMAGE](state, image){
    VuexHelpers.createOrUpdate(state.proctoringImages, image, ["file_name"])
  },
  [types.PROCTORING_IMAGES_LOADING](state, loading){
    state.proctoringImagesLoading = loading
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
