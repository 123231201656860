import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  studentEvents: [],
}

// getters
const getters = {
  studentEvents: state => (scoreId) => {
    return state.studentEvents.filter(e => e.score_id === scoreId)
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_STUDENT_EVENT]({ studentEvents }, studentEvent){
    VuexHelpers.createOrUpdate(studentEvents, studentEvent)
  },
  [types.CREATE_OR_UPDATE_STUDENT_EVENTS]({ studentEvents }, newStudentEvents){
    newStudentEvents.forEach(
      studentEvent => VuexHelpers.createOrUpdate(studentEvents, studentEvent)
    )
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
