import Vue from "vue"

const VuexHelpers = {

  createOrUpdate(collection, newObject, indexesName = ["id"]){
    const index = collection.findIndex(e => {
      return indexesName.map(indexName =>
        e[indexName] === newObject[indexName]
      ).every(x => x === true)
    })

    if (collection[index]) {
      Vue.set(collection, index, { ...collection[index], ...newObject })
    } else {
      collection.push(newObject)
    }
  },

  newAppNotification(context, { message, type }){
    context.dispatch("newAppNotification", { message, type })
  },

}

export default VuexHelpers
