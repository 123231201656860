import * as types from "../mutation-types"
import axios from "axios"
import api from "../../api"
import jsonApii from "../../helpers/json_apii"
import VuexHelpers from "../../helpers/vuex_helpers"

// initial state
const state = {
  users : [],
  usersCount: 0,
}

// getters
const getters = {
  users: state => state.users,
  usersCount: state => state.usersCount,
  getUserById: state => id => state.users.find(user => user.id === id),
}

// actions
const actions = {
  fetchUsers(context, { filter, pagination, sort, search } = { filter: {}, pagination: {}, sort: {}, search: {} }) {
    const { commit } = context
    return axios.get(api.v1.usersUrl(), {
      params: {
        filter,
        pagination,
        sort,
        search,
      },
      paramsSerializer: jsonApii.toFilters,
    })
      .then( response => {
        commit(types.UPDATE_USERS,jsonApii.getData(response.data))
        commit(types.UPDATE_USERS_COUNT, jsonApii.getMeta(response.data))
        return jsonApii.getData(response.data)
      }).catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Erreur lors de la récupération des utilisateurs " + error, type: "error" }
        )
      })
  },
  fetchUser({ commit }, id) {
    axios.get(api.v1.userUrl(id))
      .then( response => {
        commit(types.CREATE_OR_UPDATE_USER, jsonApii.getData(response.data))
      })
  },
  destroyUser({ commit }, id) {
    return axios.delete(api.v1.userUrl(id))
      .then( () => {
        commit(types.DESTROY_USER, id)
      })
  },
  loginUser({ commit }, user) {
    return axios.post(api.v1.userLoginUrl(), { user })
  },
  passwordLessLogin({ commit }, { user: user }) {
    return axios.post(api.v1.passwordLessLoginUrl(), { user: user })
  },
  requestPasswordReset({ commit }, user) {
    return axios.post(api.v1.resetPasswordUrl(), { user })
  },
  resetPassword({ commit }, user) {
    return axios.put(api.v1.resetPasswordUrl(), { user })
  },
}

// mutations
const mutations = {
  [types.UPDATE_USERS](state, users){
    users.forEach(user => {
      VuexHelpers.createOrUpdate(state.users, user)
    })
  },
  [types.UPDATE_USERS_COUNT](state, meta) {
    state.usersCount = meta.total
  },
  [types.CREATE_OR_UPDATE_USER](state, user){
    const index = state.users.findIndex(e => e.id === user.id)
    if (state.users[index]) {
      state.users[index] = user
    } else {
      state.users.push(user)
    }
  },
  [types.DESTROY_USER](state, id) {
    let index = state.users.findIndex(e => e.id === id)
    state.users.splice(index, 1)
  },
  [types.DESTROY_USERS](state) {
    state.users = []
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
