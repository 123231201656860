class BaseModel {
  constructor(resource) {
    if(!resource) return

    Object.keys(resource).map(o => {
      this[o] = resource[o]
    })
  }
}

export { BaseModel }
