import { BaseModel } from "../../../../models/baseModel"


const icons = [
  { contentTypes: ["application/pdf"], icon: "mdi-file-pdf-box" },
  { contentTypes: ["audio/mpeg", "audio/mp3"], icon: "mdi-file-music-outline" },
]

class QuizDocument extends BaseModel {

  constructor(resource) {
    super(resource)
  }

  /**
   * @return {Boolean}
   */
  get isPDF() {
    return this.content_type === "application/pdf"
  }

  /**
   * @return {Boolean}
   */
  get isMP3() {
    return ["audio/mpeg", "audio/mp3"].includes(this.content_type)
  }

  get icon() {
    const icon = icons.find((i) => i.contentTypes.includes(this.content_type))
    return icon ? icon.icon : "mdi-file-question-outline"
  }

  /**
   * @return {Number}
   */
  getPlayCount(instance) {
    return localStorage.getItem(this.playCountKey(instance)) || 0
  }

  setPlayCount(instance, val) {
    return localStorage.setItem(this.playCountKey(instance), val)
  }

  playCountKey(instance) {
    return `document_${this.uuid}_${instance.id}_play`
  }

}

export default {
  QuizDocument,
}
