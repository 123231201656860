import * as types from "../mutation-types"
import axios from "axios"
import jsonApii from "../../helpers/json_apii"
import VuexHelpers from "../../helpers/vuex_helpers"

// initial state
const state = {
  schoolsUsers : [],
  schoolsUsersCount: 0,
}

// getters
const getters = {
  schoolsUsers: (state, rootGetters) => {
    const currentSchool = rootGetters.currentSchool
    return state.schoolsUsers.filter( s => s.school_id === currentSchool?.id)
  },
  // TODO: remove this getter once we will be able to get AccessibilityOption by QuizzesSubscription. This is only used to get the AccessibilityOption from the schoolsUser related to the displayed quiz.
  allSchoolsUsers: (state) => {
    return state.schoolsUsers
  },
  schoolsUsersCount: state => state.schoolsUsersCount,
  schoolsUser: state => id => state.schoolsUsers.find(s => s.id === id),
  currentSchoolsUser: (state, getters, rootState, rootGetters) => {
    const currentUser = rootGetters.currentUser
    return state.schoolsUsers.find(s =>
      s.user_id === currentUser.id &&
      s.school_id === currentUser.last_school_id
    )
  },
  currentSchoolsUsers: (state, getters, rootState, rootGetters) => {
    const currentUser = rootGetters.currentUser
    return state.schoolsUsers.filter(s =>
      s.user_id === currentUser.id
    )
  },
}

// actions
const actions = {
  fetchSchoolsUsers(context, { school, filter, pagination, sort, search } = { filter: {}, pagination: {}, sort: {}, search: {} }) {
    const { commit } = context
    return axios.get(school.links.schools_users, {
      params: {
        filter,
        pagination,
        sort,
        search,
      },
      paramsSerializer: jsonApii.toFilters,
    })
      .then( response => {
        commit(types.UPDATE_SCHOOL_USERS,jsonApii.getData(response.data))
        commit(types.UPDATE_SCHOOL_USERS_COUNT, jsonApii.getMeta(response.data))
        jsonApii.getIncluded(response.data, "quizzes_accessibility_option").forEach( qao => {
          commit(types.CREATE_OR_UPDATE_ACCESSIBILITY_OPTION, qao)
        })
        return jsonApii.getData(response.data)
      }).catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Error getting SchoolsUsers " + error, type: "error" }
        )
      })
  },

  fetchSchoolsUser(context, { schoolsUser }) {
    console.log("fetchSchoolsUser", schoolsUser)
    const { commit } = context
    return axios.get(schoolsUser.links.self)
      .then( response => {
        // console.log("fetchSchoolsUser", response.data)
        commit(types.UPDATE_SCHOOL_USER, jsonApii.getData(response.data))
        commit(types.CREATE_OR_UPDATE_SCORES, jsonApii.getIncluded(response.data, "score"))
        commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, jsonApii.getIncluded(response.data, "quizzes_subscription"))
        commit(types.UPDATE_QUIZZES, jsonApii.getIncluded(response.data, "quiz"))
        commit(types.UPDATE_QUIZ_INSTANCES, jsonApii.getIncluded(response.data, "instance"))
        commit(types.UPDATE_SCHOOL_USERS, jsonApii.getIncluded(response.data, "schools_user"))
        commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, jsonApii.getIncluded(response.data, "quizzes_subscription"))
        jsonApii.getIncluded(response.data, "quizzes_accessibility_option").forEach( qao => {
          commit(types.CREATE_OR_UPDATE_ACCESSIBILITY_OPTION, qao)
        })
      })
      .catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Error getting SchoolsUser " + error, type: "error" }
        )
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_SCHOOL_USERS](state, schoolsUsers){
    schoolsUsers.forEach(user => {
      VuexHelpers.createOrUpdate(state.schoolsUsers, user)
    })
  },
  [types.UPDATE_SCHOOL_USER](state, schoolsUser){
    VuexHelpers.createOrUpdate(state.schoolsUsers, schoolsUser)
  },
  [types.UPDATE_SCHOOL_USERS_COUNT](state, meta) {
    state.schoolsUsersCount = meta.total
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
