import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"

// initial state
const state = {
  questions: [],
  questionsLoading: true,
}

// getters
const getters = {
  questionById: state => id => state.questions.find(q => q.id === id),
  questionsByQuizId: state => quizId => {
    return state.questions.filter(q => {
      return q.quiz_id === quizId
    }).sort((a, b) =>  a.id - b.id)
  },
  openQuestionsWithAnswersByQuizId: (state, getters) => quizId => {
    return getters.questionsStatsByQuizId(quizId).filter(q => q.type === "open" && q.answers_count >= 1)
  },
}

// actions
const actions = {
  fetchQuestionsByQuiz(context, quiz) {
    if (!quiz?.links?.questions) return
    const { commit } = context

    commit(types.UPDATE_QUESTIONS_LOADING, true)

    return axios
      .get(quiz.links.questions)
      .then( response => {
        commit(types.CREATE_OR_UPDATE_QUESTIONS,jsonApii.getData(response.data))
        commit(types.UPDATE_QUESTIONS_LOADING, false)
      })
      .catch( error => {
        commit(types.UPDATE_QUESTIONS_LOADING, false)
        VuexHelpers.newAppNotification(
          context,
          { message: "Questions stats fetch error: " + error, type: "error" }
        )
      })
  },
  updateQuestion(context, question) {
    axios
      .patch(
        question.links.self,
        question
      )
      .then( response => {
        context.commit(types.CREATE_OR_UPDATE_QUESTION,jsonApii.getData(response.data))
      })
  },
  dropAllQuestions(context, quiz) {
    context.getters.questionsByQuizId(quiz.id).forEach(
      question => context.commit(types.DELETE_QUESTION, question)
    )
  },

}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_QUESTIONS]({ questions }, newQuestions){
    newQuestions.forEach(question =>
      VuexHelpers.createOrUpdate(questions, question)
    )
  },
  [types.CREATE_OR_UPDATE_QUESTION]({ questions }, question){
    VuexHelpers.createOrUpdate(questions, question)
  },
  [types.UPDATE_QUESTIONS_LOADING](state, status){
    state.questionsLoading = status
  },
  [types.DELETE_QUESTION](state, question){
    let index = state.questions.findIndex(e => e.id === question.id)
    state.questions.splice(index, 1)
  },
  [types.FLUSH_QUESTIONS](state ){
    state.questions = []
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
