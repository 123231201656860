import vueI18n from "../../../plugins/i18n"

class BaseQuestionBehavior {

  /**
  * @param {QuestionDraft, QuestionInstance} questionNode
  */
  constructor(questionNode) {
    this.questionNode = questionNode
  }

  /**
   * @return {Array<ChoiceDraft>}
   */
  defaultChildren() { return [] }

  /**
   * @return {Boolean}
   */
  get commentable() { return true }

  /**
   * @return {Boolean}
   */
  get canHaveExpectedAnswer() { return false }

  /**
   * @return {Boolean}
   */
  get indexable() { return true }

  /**
   * @return {Boolean}
   */
  get isAnswerable() { return true }

  /**
   * @return {String, undefined}
   */
  get placeholder() { return vueI18n.t("evalmee.home.teacher.quizEditor.question.all.placeholder") }

  /**
   * @return {Number}
   */
  get minHeight() { return 200 }

  /**
   * @return {Boolean}
   */
  hasEnoughCorrectChoices() { return true }

  /**
   * @return {Boolean}
   */
  hasEnoughChoices() { return true }

  /**
   * @param {Number} position
   * @param {Number} questionsCount
   * @param {Boolean} paper
   *
   * @return {String}
   */
  subtitle(position, questionsCount, paper) {
    if(paper) { return position }
    if(questionsCount > 1) { return `Question ${position} / ${questionsCount}` }

    return ""
  }

  /**
   * @returns {Number, null}
   */
  correctChoicesCount() {
    return null
  }

  /**
   * @return {String, undefined}
   */
  subtitleInstructions() { return null }

  /**
   * @return {Boolean, undefined}
   */
  get allowMultipleChoices() { return null }

  /**
   * @return {Boolean, undefined}
   */
  get allowTags() { return true }

  /**
   * @return {Number, undefined}
   */
  get positionInQuiz() {
    return this.questionNode.indexIterator?.next()
  }
}

export { BaseQuestionBehavior }
