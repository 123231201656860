import { BaseQuestionBehavior } from "./base_question_behavior"
import vueI18n from "../../../plugins/i18n"


class TextBehavior extends BaseQuestionBehavior {

  get commentable() { return false }

  get indexable() { return false }

  get isAnswerable() { return false }

  get minHeight() { return 50 }

  get placeholder() {
    return vueI18n.t("evalmee.home.teacher.quizEditor.question.text.placeholder")
  }

  // eslint-disable-next-line no-unused-vars
  subtitle(position, questionsCount) {
    return null
  }

  get allowTags() { return false }

  get positionInQuiz() {
    return null
  }

}

export { TextBehavior }
