import * as types from "../mutation-types"
import axios from "axios"
import api from "../../api"
import jsonApii from "../../helpers/json_apii"
import VuexHelpers from "../../helpers/vuex_helpers"


// initial state
const state = {
  emails : [],
}

// getters
const getters = {
  emails: state => state.emails,
  emailsBy: state => (emailable, emailableId, subscriptionId ) => {
    return state.emails.filter(e => {
      return e.emailable_type === emailable &&
             e.emailable_id === emailableId &&
             e.quizzes_subscription_id === subscriptionId
    } )
  },
}

// actions
const actions = {
  fetchEmails({ commit },{ emailable, emailableId }) {
    return axios
      .get(
        api.v1.emailsUrl(),
        {
          params: {
            filter: { emailable_type: emailable, emailable_id: emailableId },
          },
          paramsSerializer: jsonApii.toFilters,
        },
      )
      .then( responce => {
        jsonApii.getData(responce.data).forEach( email => {
          commit(types.UPDATE_EMAIL, email)
        })
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_EMAIL](state, email){
    VuexHelpers.createOrUpdate(state.emails, email)
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
