import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  taskProgressManagers: [],
}

// getters
const getters = {
  taskProgressManagersByQuizId: state => (quizId) =>  {
    return state.taskProgressManagers.filter(t => t.quiz_id === quizId)
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [types.UPDATE_TASK_PROGRESS_MANAGERS](state, tpms){
    tpms.forEach(tpm => {
      VuexHelpers.createOrUpdate(state.taskProgressManagers, tpm)
    })
  },
  [types.UPDATE_TASK_PROGRESS_MANAGER](state, tpm){
    VuexHelpers.createOrUpdate(state.taskProgressManagers, tpm)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
