class QuizNode {
  /**
   * Return element index according to the indexable status of
   * the previous elements
   *
   * @param {DraftNode} child
   * @param {Number} index
   * @return {Number}
   */
  childIndex(index, child) {
    let indexableChildrenBeforeIndexCount = [ ...this.children ]
      .splice(0, index)
      .filter(child => child.indexable).length

    if(child.indexable) return indexableChildrenBeforeIndexCount
  }

  /**
   * Return if a node is present in question index
   * @return {boolean}
   */
  get indexable() { return true }

  /**
   * @return {Number}
   */
  get indexableChildrenCount() {
    return this.children.filter(c => c.indexable).length
  }

  /**
   * Return if a node of its children are answerable
   * @return {boolean}
   */
  get isAnswerable() { return true }

}
export { QuizNode }
