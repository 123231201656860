import * as types from "../../mutation-types"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  questionsTagsCategory: [],
}

// getters
const getters = {
  questionsTagsCategory: state => state.questionsTagsCategory,
  questionTagsCategoryById: state => id => state.questionsTagsCategory.find(c => c.id === id),
  questionsTagsCategoryByQuizId: state => quizId => state.questionsTagsCategory.filter(category => category.quiz_id === quizId),
}

// actions
const actions = {
  updateQuestionsTagsCategory(context, category) {
    axios
      .patch(category.links.self, category)
      .then( response => {
        context.commit(types.CREATE_OR_UPDATE_QUESTION_TAGS_CATEGORY, jsonApii.getData(response.data))
      })
  },
  deleteQuestionsTagsCategory(context, category) {
    axios
      .delete(category.links.self)
      .then( () => { context.commit(types.DELETE_QUESTION_TAGS_CATEGORY, category) })
      .catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Erreur lors de la suppression de la catégorie " + error, type: "error" }
        )
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_QUESTIONS_TAGS_CATEGORY](state, categories){
    categories.forEach(
      category => VuexHelpers.createOrUpdate(state.questionsTagsCategory, category)
    )
  },
  [types.CREATE_OR_UPDATE_QUESTION_TAGS_CATEGORY](state, category){
    VuexHelpers.createOrUpdate(state.questionsTagsCategory, category)
  },
  [types.DELETE_QUESTION_TAGS_CATEGORY]({ questionsTagsCategory }, category){
    let index = questionsTagsCategory.findIndex(e => e.id === category.id)
    questionsTagsCategory.splice(index, 1)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
