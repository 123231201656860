import * as types from "../../mutation-types"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"
import VuexHelpers from "../../../helpers/vuex_helpers"


// initial state
const state = {
  scanSheets: [],
  scansLoading: false,
}

// getters
const getters = {
  scanSheets: state => state.scanSheets,
  scanSheetsByQuizId: state => quizId => state.scanSheets.filter(s => s.quiz_id === quizId),
  getScanSheetById: ({ scanSheets }) => id => scanSheets.find(i => i.id == id),
  getNextScanSheetById: ({ scanSheets }) => id => {
    const index = scanSheets.findIndex(i => i.id == id)
    return scanSheets[index + 1]
  },
  getPreviousScanSheetById: ({ scanSheets }) => id => {
    const index = scanSheets.findIndex(i => i.id == id)
    return scanSheets[index - 1]
  },
  scansLoading: state => state.scansLoading,
  scansWithDuplicateStudent: (state, getters) => quizId => {
    let scans = getters.scanSheetsByQuizId(quizId)
    let sorted_arr = scans.slice().sort((a, b) => a.user_id - b.user_id) // You can define the comparing function here.
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    let results = []
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1].user_id === sorted_arr[i].user_id) {
        results.push(sorted_arr[i])
      }
    }
    return results
  },
  scanCountByType: state => {
    return {
      completed: state.scanSheets.filter(s => s.state === "completed").length,
      student_not_found: state.scanSheets.filter(
        s => s.state === "student_not_found"
      ).length,
      omr_error: state.scanSheets.filter(s => s.state === "omr_error").length,
      studentsToValidate: state.scanSheets.filter(s => s.state === "student_to_validate").length,
      analysis: state.scanSheets.filter(
        s => [
          "waiting_for_analysis",
          "omr_analysis",
          "omr_completed",
          "looking_for_student",
        ].includes(s.state)
      ).length,
    }
  },
}

// actions
const actions = {
  fetchScanSheet({ commit }, score) {
    axios.get(
      score.links.scanned_paper_quiz_sheet
    ).then( response => {
      commit(types.UPDATE_SCAN_SHEET,jsonApii.getData(response.data))
    })
  },
  fetchScanSheetBySelf({ commit }, scanSheet) {
    return axios.get(
      scanSheet.links.self
    ).then( response => {
      commit(types.UPDATE_SCAN_SHEET,jsonApii.getData(response.data))
    })
  },
  fetchScanSheets({ commit }, { quiz, filter, pagination, sort, search } = { filter: {}, pagination: {}, sort: {}, search: {} }) {
    commit(types.SCAN_SHEET_LOADING, true)

    return axios
      .get(
        quiz.links.scanned_sheets,
        {
          params: {
            filter,
            pagination,
            sort,
            search,
          },
          paramsSerializer: jsonApii.toFilters,
        }
      )
      .then( response => {
        const scanSheets = jsonApii.getData(response.data)
        commit(types.UPDATE_SCAN_SHEETS, scanSheets)
        commit(types.SCAN_SHEET_LOADING, false)

        const meta = jsonApii.getMeta(response.data)
        return {
          items: scanSheets,
          totalCount: meta.total,
          statistics: meta.statistics,
        }
      })
  },
  updateScanSheet({ commit }, scanSheet) {
    return axios.patch(
      scanSheet.links.self,
      { scanned_paper_quiz_sheet: scanSheet }
    )
      .then( response => {
        commit(types.UPDATE_SCAN_SHEET, jsonApii.getData(response.data))
      }).catch( error => {
        commit(types.UPDATE_SCAN_SHEET, jsonApii.parseErrors(scanSheet, error) )
        throw error
      })
  },
  updateScanSheetBarcode({ commit }, scanSheet) {
    return axios.post(
      scanSheet.links.update_barcode,
      { scanned_paper_quiz_sheet: scanSheet }
    )
      .then( response => {
        commit(types.UPDATE_SCAN_SHEET, jsonApii.getData(response.data))
      }).catch( error => {
        commit(types.UPDATE_SCAN_SHEET, jsonApii.parseErrors(scanSheet, error) )
        throw error
      })
  },
  updateScanSheetUser({ commit }, scanSheet) {
    return axios.post(
      scanSheet.links.update_user,
      { scanned_paper_quiz_sheet: scanSheet }
    )
      .then( response => {
        commit(types.UPDATE_SCAN_SHEET, jsonApii.getData(response.data))
      }).catch( error => {
        commit(types.UPDATE_SCAN_SHEET, jsonApii.parseErrors(scanSheet, error) )
        throw error
      })
  },
  updateScanSheetTickedBoxes({ commit }, scanSheet) {
    return axios.post(
      scanSheet.links.update_ticked_boxes,
      { scanned_paper_quiz_sheet: scanSheet }
    )
      .then( response => {
        commit(types.UPDATE_SCAN_SHEET, jsonApii.getData(response.data))
      }).catch( error => {
        commit(types.UPDATE_SCAN_SHEET, jsonApii.parseErrors(scanSheet, error) )
        throw error
      })
  },
  deleteScanSheet({ commit }, scanSheet) {
    return axios.delete(
      scanSheet.links.self
    )
      .then( () => {
        commit(types.DELETE_SCAN_SHEET, scanSheet)
      })
  },
  archiveScanSheet({ commit }, scanSheet) {
    return axios
      .post(scanSheet.links.archive)
      .then( (response) => {
        commit(types.UPDATE_SCAN_SHEET, jsonApii.getData(response.data))
      })
  },
  retryFailed(context, scanSheet) {
    return axios.post(
      scanSheet.links.retry_failed,
      { id: scanSheet.id }
    )
      .then( response => {
        context.commit(types.UPDATE_SCAN_SHEET, jsonApii.getData(response.data))
        context.dispatch("fetchScanSheetBySelf",scanSheet)
      })
  },
  createAnswersFromScan(context, scanSheet) {
    return axios.post(
      scanSheet.links.create_answers,
      { id: scanSheet.id }
    )
      .then( response => {
        context.commit(types.UPDATE_SCAN_SHEET, jsonApii.getData(response.data))
      })
  },
  flushScanSheetByQuiz(context, quiz) {
    context.getters.scanSheetsByQuizId(quiz.id).forEach(scanSheet =>
      context.commit(types.DELETE_SCAN_SHEET, scanSheet)
    )
  },
}

// mutations
const mutations = {
  [types.UPDATE_SCAN_SHEETS](state, scanSheets){
    scanSheets.forEach(scanSheet => {
      VuexHelpers.createOrUpdate(state.scanSheets, scanSheet)
    })
  },
  [types.UPDATE_SCAN_SHEET]({ scanSheets }, scanSheet){
    VuexHelpers.createOrUpdate(scanSheets, scanSheet)

  },
  [types.DELETE_SCAN_SHEET]({ scanSheets }, scanSheet){
    let index = scanSheets.findIndex(e => e.id === scanSheet.id)
    scanSheets.splice(index, 1)
  },
  [types.SCAN_SHEET_LOADING](state, status){
    state.scansLoading = status
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
