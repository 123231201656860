import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  quizzesStats: [],
}

// getters
const getters = {
  quizzesStat: state => (quizId) =>  {
    return state.quizzesStats.find(t => t.quiz_id === quizId)
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [types.UPDATE_QUIZZES_STATS](state, stats){
    stats.forEach(stat => {
      VuexHelpers.createOrUpdate(state.quizzesStats, stat)
    })
  },
  [types.UPDATE_QUIZZES_STAT](state, stat){
    VuexHelpers.createOrUpdate(state.quizzesStats, stat)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
