import * as types from "../../../mutation-types"
import VuexHelpers from "../../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "../../../../helpers/json_apii"

// initial state
const state = {
  stats: [],
}

// getters
const getters = {
  proctoringImagesAnalysisStats: state => state.stats,
}

// actions
const actions = {
  fetchProctoringImagesAnalysisStats(context, quiz) {
    axios
      .get(`/api/v1/quizzes/${quiz.id}/proctoring_images_stats`)
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS_STATS, response.data)
      })
      .catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Images analysis fetch error: " + error, type: "error" }
        )
      })
  },
  resetProctoringImagesAnalysisWithFaceError(context, quiz) {
    axios
      .post(`/api/v1/quizzes/${quiz.id}/proctoring_images_stats/reset_with_faces_error`)
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS_STATS, response.data)
      })
      .catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "reset images error: " + error, type: "error" }
        )
      })
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS_STATS](state, stats){
    state.stats = stats
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
