import posthog from "posthog-js"
import chatSupportHelpers from "@/helpers/chat_support_helpers"
import { useDeviceInfo } from "@/composables/useDeviceInfo"

const screebId = import.meta.env.SCREEB_IDENTIFIER
const posthogId = window.POSTHOG_FRONT_KEY

export default {
  updateLogRocketIdentification: ( { user, currentSchool }) => {
    window.LogRocket?.identify(user.id, {
      name: user.name,
      email: user.email,
      teacher: user.teacher,
      currentSchool: currentSchool?.name,
    })
  },

  updateScreebIdentification: ({ user, currentSchool }) => {
    if (!screebId) return

    window.$screeb("init", screebId, {
      identity: {
        id: user.id,
        properties: {
          school: currentSchool?.name,
          email: user.email,
          teacher: user.teacher,
          firstname: user.first_name,
          lastname: user.last_name,
          name: user.name,
          currentApp: user.last_app_used,
          language: user.language,
          last_seen_at: new Date(),
          authenticated: true,
        },
      },
    })
  },

  updateSegmentIdentification: (user) => {
    if (!window.analytics) return

    window.analytics?.identify(user.id, {
      email: user.email,
      u_first_name: user.first_name,
      u_last_name: user.last_name,
      u_email: user.email,
      u_teacher: user.teacher,
      u_language: user.language,
      t_trial_mode: user.trial_mode,
    })
  },

  trackEvent(eventName, properties = {}) {
    if (screebId) window.$screeb("event.track", eventName, properties)
    window.LogRocket?.track(eventName, properties)
    window.analytics?.track(eventName, properties)
    if (posthogId) posthog.capture(eventName, properties)
  },

  setupPostHog() {
    posthog.init(posthogId, {
      api_host: "https://eu.posthog.com",
      enable_recording_console_log: true,
      session_recording: {
        inlineStylesheet: true,
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    })
  },

  updatePostHogIdentification(user) {
    if (!posthogId) return
    if (!posthog.__loaded) this.setupPostHog()

    posthog.identify(user.id, {
      u_name: user.name,
      u_first_name: user.first_name,
      u_last_name: user.last_name,
      email: user.email,
      u_teacher: user.teacher,
      u_language: user.language,
    })

    chatSupportHelpers.updateReplayUrl(posthog.get_session_replay_url())
  },


  setupFeatureBase() {
    const script = document.createElement("script")
    script.src = "https://do.featurebase.app/js/sdk.js"
    script.id = "featurebase-sdk"
    document.head.appendChild(script)
  },

  updateFeatureBaseIdentification(org, user) {
    const win = window

    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments)
      }
    }

    const widgetCommonOptions = {
      organization: org, // Replace this with your featurebase organization name
      theme: "light", // Choose between dark or light theme
      locale: user.language, // Change the language, view all available languages from https://help.featurebase.app/en/articles/8879098-using-featurebase-in-my-language
      usersName: user.first_name, // Optional - Show the users name in the welcome message for the fullscreen popup
      jwtToken: user[`featurebase_${org}_jwt`], // Optional - Pass the JWT token to authenticate the user
    }

    win.Featurebase("initialize_changelog_widget", {
      ...widgetCommonOptions,
      placement: "top", // Choose between right, left, top, bottom placement (Optional if fullscreenPopup is enabled)
      fullscreenPopup: !user.first_sign_in_is_today, // Optional - Open a fullscreen announcement of the new feature to the user
      // alwaysShow: true,
    })

    const deviceInfo = useDeviceInfo()

    const metadata = JSON.parse(JSON.stringify({
      ...deviceInfo.deviceDetails,
      ...deviceInfo.browserInfo,
    }, (key, value) => value ?? ""))

    win.Featurebase("initialize_feedback_widget", {
      ...widgetCommonOptions,
      metadata,
    })
  },

  featureBaseUnviewedChangelogCount() {
    return window.Featurebase("unviewed_changelog_count")
  },

  featureBaseRoadmapUrl(org, user) {
    return `https://feedback.${org}.com/api/v1/auth/access/jwt?jwt=${user[`featurebase_${org}_jwt`]}&return_to=https://feedback.${org}.com/roadmap`
  },

  featureBaseFeedbackUrl(org, user) {
    return `https://feedback.${org}.com/api/v1/auth/access/jwt?jwt=${user[`featurebase_${org}_jwt`]}&return_to=https://feedback.${org}.com`
  },

  featureBaseChangelogUrl(org, user) {
    return `https://feedback.${org}.com/api/v1/auth/access/jwt?jwt=${user[`featurebase_${org}_jwt`]}&return_to=https://feedback.${org}.com/changelog`
  },

  featureBaseHelpCenterUrl(org, user) {
    return `https://help.${org}.com/api/v1/auth/access/jwt?jwt=${user[`featurebase_${org}_jwt`]}&return_to=https://help.${org}.com/${user.language}/help`
  },
}
