import * as types from "../../../mutation-types"
import VuexHelpers from "../../../../helpers/vuex_helpers"
import axios from "axios"
import jsonApii from "../../../../helpers/json_apii"

// initial state
const state = {
  images: [],
}

// getters
const getters = {
  proctoringImagesAnalysesV2: state => {
    return state.images
  },
}

// actions
const actions = {
  fetchProctoringImagesAnalysisV2(context,{ limit, search , quizId, userId } =  { limit: 10, search: "", quizId: null, userId: null }) {
    axios
      .get("/api/v1/proctoringv2_images", { params: { limit, search, quiz_id: quizId, user_id: userId } })
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS,
          jsonApii.getData(response.data)
        )
      })
      .catch( error => {
        VuexHelpers.newAppNotification(
          context,
          { message: "Images fetch error: " + error, type: "error" }
        )
      })
  },
}

// mutations
const mutations = {
  // TODO : rename this mutation
  [types.CREATE_OR_UPDATE_PROCTORING_IMAGE_ANALYSIS](state, images){
    state.images = images
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
