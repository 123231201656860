// initial state
import * as types from "@/store/mutation-types"
import VuexHelpers from "@/helpers/vuex_helpers"

const state = {
  timeAccommodationSummaries : [],
}

// getters
const getters = {
  timeAccommodationSummary: (state) => (id, parentId, parentType) => {
    // id should be compared with == because it can be null
    return state.timeAccommodationSummaries.find(ta => ta.time_accommodation_id == id && ta.parent_id === parentId && ta.parent_type === parentType)
  },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_TIME_ACCOMMODATION_SUMMARY](state, timeAccommodationSummary){
    VuexHelpers.createOrUpdate(state.timeAccommodationSummaries, timeAccommodationSummary)
  },
}

export default {
  state,
  getters,
  mutations,
}
