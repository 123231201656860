import * as types from "../../../mutation-types"
import axios from "axios"
import api from "../../../../api"
import store from "../../../index"
import lodashReplacementHelper from "../../../../helpers/lodash_replacement_helpers"
import draftNodes from "./draft_nodes"
import ViewHelpers from "@/helpers/vuex_helpers"

let debounceSavers = []

// initial state
const state = {
  quizDraft: { content: null },
  quizDrafts: [],
  state: "loading",
}

// getters
const getters = {
  quizDraft: state => quizId => {
    return state.quizDrafts.find(draft => draft.quiz_id === quizId) || { content: null }
  },
  isQuizDraftValid: (state, getters) =>
    (quizId, quizType = "online") =>
      draftNodes.hasAllNodesValid(getters.quizDraft(quizId), quizType),
  parsedDraft: (state, getters) =>
    ({ type, quizId }) =>
      draftNodes.parseDraft(getters.quizDraft(quizId), type),
  parsedExercisesPoolsDraft: (state, getters) =>
    ({ type, quizId }) => {
      return draftNodes.parseExercicePoolDraft(
        getters.quizDraft(quizId),
        type,
      )
    },
  quizDraftState: state => state.state,
  exercisesPoolsDraftByUuid: (state) => (uuid ) => {
    return state.quizDrafts.flatMap(quizDraft => {
      return draftNodes
        .parseExercicePoolDraft(quizDraft)
        .filter(exercisePool => exercisePool.uuid === uuid)
    })
  },
}

// actions
const actions = {
  fetchQuizDraft({ commit }, quizId) {
    return axios.get(api.v1.quizDraftUrl(quizId))
      .then(responce => {
        commit(types.UPDATE_QUIZ_DRAFT, responce.data)
        commit(types.UPDATE_QUIZ_DRAFT_STATE, { status: "loaded", quizId })
      })
  },
  updateQuizDraft({ commit }, quizDraft) {
    commit(types.UPDATE_QUIZ_DRAFT, quizDraft)
  },
  saveQuizDraft({ getters, commit }, { quizId }) {
    if (["loading", "saving"].includes(getters.quizDraftState)) { return }
    commit(types.UPDATE_QUIZ_DRAFT_STATE, { status: "saving", quizId })

    return axios.patch(api.v1.quizDraftUrl(quizId), getters.quizDraft(quizId))
      .then(() => {
        commit(types.UPDATE_QUIZ_DRAFT_STATE, {
          status: "saved",
          updated_at: Date.now(),
          quizId,
        })
      }).catch((error) => {
        console.log({ error: error })
        commit(types.UPDATE_QUIZ_DRAFT_STATE, { status: "error", quizId })
      }).finally(
        commit(types.FLUSH_QUESTIONS)
      )
  },

  updateDraftState({ commit }, { state, quizId }) {
    commit(types.UPDATE_QUIZ_DRAFT_STATE,{ status: state, quizId })
  },

  // * This is a debounced version of saveQuizDraft
  // * It save the draft after 5 seconds of inactivity
  debouncedSaveQuizDraft(_vuexObj, { quizId }) {
    let debouncer = debounceSavers.find(ds => ds.quizId === quizId)

    if (!debouncer) {
      debouncer = {
        quizId: quizId,
        debounce: lodashReplacementHelper.debounce((args) => {
          store.dispatch("saveQuizDraft", args)
        }, 5000),
      }
      debounceSavers.push(debouncer)
    }

    debouncer.debounce({ quizId })
  },
}

// mutations
const mutations = {
  [types.UPDATE_QUIZ_DRAFT](state, quizDraft) {
    ViewHelpers.createOrUpdate(state.quizDrafts, quizDraft, ["quiz_id"])
  },
  [types.UPDATE_QUIZ_DRAFT_STATE](state, {
    status,
    quizId,
    updated_at,
  } = { updated_at: null }) {
    state.state = status
    if (updated_at) { this.getters.quizDraft(quizId).updated_at = updated_at }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
