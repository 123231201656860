import * as Sentry from "@sentry/vue"
import Vue from "vue"

const sentryDsn = window.SENTRY_DSN_FRONT

export default {
  initSentry: (router = null) => {
    Sentry.init({
      Vue,
      dsn: sentryDsn,
      environment: process.env.NODE_ENV,
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/app\.evalmee\.com\/api/,
          ],
          ...(router
            ? {
              routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }
            : null),
        }),
        new Sentry.Replay({ networkDetailAllowUrls: [window?.location?.origin] }),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.01, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 50%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  },

  sentrySetUser: ({ user, currentSchool, schools }) => {
    Sentry.setUser({
      id: user.id,
      username: user.name,
      email: user.email,
      teacher: user.teacher,
      current_school: currentSchool?.name,
      schools: schools.map(school => school.name).join(", "),
    })
  },

}
