import { BaseModel } from "../../../../models/baseModel"
import store from "../../../index"


class QuizInstance extends BaseModel {

  constructor(resource) {
    super(resource)
  }

  /**
   * @return {Number}
   */
  get timeOffset() {
    return store.getters.timeOffset
  }

  /**
   * @return {Object}
   */
  get quiz() {
    return store.getters.quizById(this.quiz_id)
  }
}

export default {
  QuizInstance,
}
