import axios from "axios"
import * as types from "../../../mutation-types"
import jsonApii from "../../../../helpers/json_apii"
import VuexHelpers from "../../../../helpers/vuex_helpers"


// initial state
const state = {
  messages: [],
}

// getters
const getters = {
  messages: state => channelId =>  {
    return state.messages.filter(m => m.quizzes_chat_channel_id === channelId).sort((a, b) => {
      return new Date(a.created_at) - new Date(b.created_at)
    })
  },
}

// actions
const actions = {
  fetchMessagesByChannel({ commit }, channel) {
    axios
      .get(channel.links.messages)
      .then( response => {
        commit(types.UPDATE_CHAT_MESSAGES, jsonApii.getData(response.data))
      })
  },

  createMessageForChannel({ commit }, { channel, userId, content }) {
    axios
      .post(
        channel.links.messages,
        {
          user_id: userId,
          content: content,
        }
      )
      .then( response => {
        commit(types.UPDATE_CHAT_MESSAGE, jsonApii.getData(response.data))
      })
      .catch()
  },
  updateMessageOnChannel({ commit }, { message, status }) {
    axios
      .patch(message.links.self, { status: status })
      .then( response => {
        commit(types.UPDATE_CHAT_MESSAGE, jsonApii.getData(response.data))
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_CHAT_MESSAGES](state, messages){
    state.messages = messages
  },

  [types.UPDATE_CHAT_MESSAGE](state, messages){
    VuexHelpers.createOrUpdate(state.messages, messages)
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
