import * as types from "../../mutation-types"
import axios from "axios"
import api from "../../../api"
import jsonApii from "../../../helpers/json_apii"


// initial state
const state = {
  moodleCourses : [],
}

// getters
const getters = {
  moodleCourses: state => state.moodleCourses,
}

// actions
const actions = {
  fetchMoodleCourses({ commit }, categoryId) {
    return axios
      .get(
        api.v1.moodleCoursesUrl(),
        { params: { category_id: categoryId } }
      )
      .then( responce => {
        commit(types.UPDATE_MOODLE_COURSES, jsonApii.getData(responce.data))
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_MOODLE_COURSES](state, courses){
    state.moodleCourses = courses
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
