import { McqBehavior } from "./mqc_behavior"
import { OpenBehavior } from "./open_behavior"
import { TextBehavior } from "./text_behavior"
import { SpreadsheetBehavior } from "@/behaviors/quiz/question/spreadsheet_behavior"

class QuestionBehaviorFor {

  constructor(question) {
    this.question = question
  }

  /**
   * @return {McqBehavior, OpenBehavior, TextBehavior, SpreadsheetBehavior,null}
   */
  get behavior() {
    if (this.question.isMcq)         return new McqBehavior(this.question)
    if (this.question.isOpen)        return new OpenBehavior(this.question)
    if (this.question.isSpreadsheet) return new SpreadsheetBehavior(this.question)
    if (this.question.isText)        return new TextBehavior(this.question)
    return null
  }

}

export { QuestionBehaviorFor }
