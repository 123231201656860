import * as types from "../../mutation-types"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  paperQuizzes: [],
}

// getters
const getters = {
  paperQuiz: state => (quizId) =>  {
    return state.paperQuizzes.find(p => p.quiz_id === quizId)
  },
}

// actions
const actions = {

}

// mutations
const mutations = {
  [types.UPDATE_PAPER_QUIZ](state, paperQuiz){
    VuexHelpers.createOrUpdate(state.paperQuizzes, paperQuiz)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
