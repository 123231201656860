import * as types from "../mutation-types"
import axios from "axios"
import api from "../../api"
import jsonApii from "../../helpers/json_apii"
import VuexHelpers from "../../helpers/vuex_helpers"

// initial state
const state = {
  groups : [],
  groupsCount : 0,
  // group : {},
  groupMembers : [],
}

// getters
const getters = {
  groups: state => state.groups,
  groupsCount: state => state.groupsCount,
  groupMembers: state => state.groupMembers,
  getGroupById: state => id => state.groups.find(g => g.id === id),
}

// actions
const actions = {
  fetchGroups({ commit, rootGetters }, { filter, pagination, sort, search } = { filter: {}, pagination: {}, sort: {}, search: {} }) {
    return axios.get(api.v1.groupsUrl(), { params: {
      filter: { ...filter, school_id: rootGetters.currentSchool.id },
      pagination,
      sort,
      search,
    }, paramsSerializer: jsonApii.toFilters })
      .then( response => {
        commit(types.UPDATE_GROUPS,jsonApii.getData(response.data))
        commit(types.UPDATE_GROUPS_COUNT, jsonApii.getMeta(response.data))
        return jsonApii.getData(response.data)
      })
  },
  fetchGroup({ commit }, id) {
    return axios.get(api.v1.groupUrl(id))
      .then( response => {
        commit(types.UPDATE_GROUP,jsonApii.getData(response.data))
      })
  },
  updateGroup({ commit }, group) {
    return axios.patch(group.links.self, { group })
      .then( response => {
        commit(types.UPDATE_GROUP, jsonApii.getData(response.data))
        return jsonApii.getData(response.data)
      })
  },
  destroyGroup({ commit }, group) {
    return axios.delete(group.links.self).then( () => {
      commit(types.DELETE_GROUP)
    })
  },
  destroyGroupMembers({ commit }, { group, filter } = { group: {}, filter: {} }) {
    console.log(filter)
    return axios.delete(group.links.members, { params: {
      filter,
    }, paramsSerializer: jsonApii.toFilters,
    }).then( () => {
      commit(types.DELETE_GROUP_MEMBERS)
    })
  },
  createGroup({ commit }, group) {
    return axios.post(api.v1.groupsUrl(), group).then( response => {
      commit(types.CREATE_GROUP, jsonApii.getData(response.data))
    })
  },
  createGroupMembers({ commit }, { group, emails }) {
    return axios.post(group.links.members, { group: group, emails: emails }).then( response => {
      commit(types.UPDATE_GROUP_MEMBERS, jsonApii.getData(response.data))
    })
  },
  fetchGroupMembers({ commit }, { group, filter, pagination, sort, search } = { filter: {}, pagination: {}, sort: {}, search: {} }) {
    return axios.get(group.links.members, { params: {
      filter,
      pagination,
      sort,
      search,
    }, paramsSerializer: jsonApii.toFilters,
    })
      .then( response => {
        commit(types.UPDATE_GROUP_MEMBERS, jsonApii.getData(response.data))
        return jsonApii.getData(response.data)
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_GROUPS](state, groups){
    state.groups = groups
  },
  [types.UPDATE_GROUP](state, group) {
    VuexHelpers.createOrUpdate(state.groups, group)
  },
  [types.DELETE_GROUP](state) {
    state.group = {}
  },
  [types.CREATE_GROUP](state, group) {
    state.group = group
  },
  [types.UPDATE_GROUP_MEMBERS](state, groupMembers) {
    state.groupMembers = groupMembers
  },
  [types.DELETE_GROUP_MEMBERS](state) {
    state.groupMembers = {}
  },
  [types.UPDATE_GROUPS_COUNT](state, meta) {
    state.groupsCount = meta.total
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
