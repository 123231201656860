import * as types from "../mutation-types"
import axios from "axios"
import api from "../../api"
import jsonApii from "../../helpers/json_apii"
import VuexHelpers from "../../helpers/vuex_helpers"
import vueI18n from "../../plugins/i18n"

const state = {
  quizzes : [],
  answers: [],
  quizzesLoading: false,
  currentCorrectionLoading: false,
  quizzesCount: 0,
}

// getters
const getters = {
  quizzes: (state, rootGetters) => state.quizzes.sort((a, b) => a.id - b.id).filter( q => q.school_id === rootGetters.currentSchool.id),
  allSchoolsQuizzes: state => state.quizzes,
  quizzesCount: state => state.quizzesCount,
  quizzesLoading: state => state.quizzesLoading,
  currentCorrectionLoading: state => state.currentCorrectionLoading,

  quizById: state => id => state.quizzes.find(q => q.id === parseInt(id)),

  isQuizAnsweredById:(state, getters) => (quiz, user) => {
    let quizAnswers = getters
      .answersByQuestionsAndUserId(quiz?.question_ids, user.id) || []

    return quizAnswers.length === quiz?.question_ids.length
  },

  areAllAnswersSynchronized: (state, rootGetters) => {
    return (quiz, userId) => {
      return rootGetters
        .answersByQuestionsAndUserId(
          quiz?.question_ids, userId).filter(a => a.synchronized === false
        )
        .length === 0
    }
  },

  answersSyncStats: (state, rootGetters) => {
    return (quiz, userId) => {

      const answers = rootGetters.answersByQuestionsAndUserId(
        quiz?.question_ids,
        userId
      )

      return {
        sync: answers.filter(a => a.synchronized === true).length,
        notSync: answers.filter(a => a.synchronized === false).length,
      }
    }
  },

  quizAllowedActions: () => (quiz) => {
    if(!quiz) return

    if(quiz.quiz_type === "paper") {
      if(quiz?.paper_quiz?.build_status === "created") {
        return {
          present: false,
          edit: true,
          subjectContentEdition: true,
          presentScores: false,
        }
      } else {
        return {
          present: true,
          edit: true,
          subjectContentEdition: false,
          presentScores: true,
        }
      }

    }

    switch(quiz.state) {
    case "draft" :
      return {
        publish: true,
        launch: true,
        present: false,
        stop: false,
        startPreview: true,
        stopPreview: false,
        edit: true,
        subjectContentEdition: true,
        editStopDate: true,
        presentScores: false,
        editProctoring: true,
        enableProctoring: true,
      }
    case "preview" :
      return {
        publish: true,
        launch: true,
        present: false,
        stop: false,
        startPreview: false,
        stopPreview: true,
        edit: true,
        subjectContentEdition: false,
        editStopDate: false,
        presentScores: false,
        editProctoring: false,
        enableProctoring: quiz.video_proctoring,
      }
    case "saving" :
      return {
        publish: false,
        launch: false,
        present: false,
        stop: false,
        startPreview: false,
        stopPreview: false,
        edit: false,
        subjectContentEdition: false,
        editStopDate: false,
        presentScores: false,
        editProctoring: false,
        enableProctoring: quiz.video_proctoring,
      }
    case "creating_instances" :
    case "published" :
      return {
        publish: false,
        launch: quiz.instances_count >= quiz.subscribed_count,
        present: true,
        stop: true,
        startPreview: false,
        stopPreview: false,
        edit: false,
        subjectContentEdition: false,
        editStopDate: true,
        presentScores: false,
        editProctoring: false,
        enableProctoring: quiz.video_proctoring,
      }
    case "active" :
    case "paused" :
      return {
        publish: false,
        launch: false,
        present: true,
        stop: true,
        startPreview: false,
        stopPreview: false,
        edit: true,
        subjectContentEdition: false,
        editStopDate: true,
        presentScores: false,
        editProctoring: false,
        enableProctoring: quiz.video_proctoring,
      }
    case "over" :
      return {
        publish: true,
        launch: true,
        present: false,
        stop: false,
        startPreview: false,
        stopPreview: false,
        edit: true,
        subjectContentEdition: false,
        editStopDate: true,
        presentScores: true,
        editProctoring: true,
        enableProctoring: true,
      }
    case "data_deleted" :
      return {
        publish: false,
        launch: false,
        present: false,
        startPreview: false,
        stopPreview: false,
        stop: false,
        edit: true,
        subjectContentEdition: false,
        editStopDate: false,
        presentScores: true,
        editProctoring: false,
        enableProctoring: false,
      }
    }
  },
}

// actions
const actions = {
  fetchOwnedQuizzes({ commit, dispatch, rootGetters }, { loader, studentMode, filter, pagination, sort, search } = { loader: true, studentMode: false, filter: {}, pagination: {}, sort: {}, search: {} } ) {
    if(loader) { commit(types.QUIZZES_LOADING, true) }
    return axios
      .get(api.v1.quizzesUrl(), {
        params: {
          filter: { ...filter, school_id: rootGetters.currentSchool.id },
          pagination,
          sort,
          search,
          student_mode: studentMode,
        },
        paramsSerializer: jsonApii.toFilters,
      })
      .then( response => dispatch("processQuizzesResponse", response) )
  },

  fetchSchoolQuizzes({ commit, dispatch }, { loader, studentMode, filter, pagination, sort, search, school } = { loader: true, studentMode: false, filter: {}, pagination: {}, sort: {}, search: {} } ) {
    if(loader) { commit(types.QUIZZES_LOADING, true) }
    return axios
      .get(api.v1.schoolQuizzesUrl(school?.id), {
        params: {
          filter,
          pagination,
          sort,
          search,
        },
        paramsSerializer: jsonApii.toFilters,
      })
      .then( response => dispatch("processQuizzesResponse", response) )
  },

  fetchSubscribedQuizzes({ dispatch }, { filter, pagination, sort, search } = { filter: {}, pagination: {}, sort: {}, search: {} } ) {
    return axios
      .get(api.v1.subscribedQuizzesUrl(), {
        params: {
          filter,
          pagination,
          sort,
          search,
        },
        paramsSerializer: jsonApii.toFilters,
      })
      .then( response => dispatch("processQuizzesResponse", response) )
  },

  processQuizzesResponse({ commit }, response) {
    commit(types.UPDATE_QUIZZES, jsonApii.getData(response.data))
    commit(types.QUIZZES_LOADING, false)
    commit(types.UPDATE_QUIZZES_COUNT, jsonApii.getMeta(response.data))
    commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, jsonApii.getIncluded(response.data, "quizzes_subscription"))
    commit(types.UPDATE_SCHOOL_USERS, jsonApii.getIncluded(response.data, "schools_user"))
    commit(types.UPDATE_USERS, jsonApii.getIncluded(response.data, "user"),  { root: true })
    commit(types.UPDATE_QUIZZES_GROUPS, jsonApii.getIncluded(response.data, "quizzes_group"))

    jsonApii.getIncluded(response.data,"time_accommodation").forEach( ta => {
      commit(types.CREATE_OR_UPDATE_TIME_ACCOMMODATION, ta, { root: true })
    })

    jsonApii.getIncluded(response.data,"time_accommodation_summary").forEach( ta => {
      commit(types.CREATE_OR_UPDATE_TIME_ACCOMMODATION_SUMMARY, ta, { root: true })
    })

    commit(
      types.UPDATE_QUIZZES_TAGS,
      jsonApii.getIncluded(response.data,"quizzes_tag"),
      { root: true }
    )
    commit(
      types.CREATE_OR_UPDATE_QUIZ_SESSIONS,
      jsonApii.getIncluded(response.data,"quizzes_session"),
      { root: true }
    )
    jsonApii.getIncluded(response.data,"paper_quiz").forEach( paperQuiz =>
      commit(types.UPDATE_PAPER_QUIZ, paperQuiz, { root: true })
    )
    commit(types.UPDATE_QUIZZES_STATS, jsonApii.getIncluded(response.data, "quizzes_stat"),  { root: true })
    return jsonApii.getData(response.data)
  },

  fetchQuiz({ commit },quiz_id) {
    return axios
      .get(api.v1.quizUrl(quiz_id))
      .then( response => {
        commit(types.CREATE_OR_UPDATE_QUIZZ, jsonApii.getData(response.data))

        commit(
          types.UPDATE_SCAN_PAPERS,
          jsonApii.getIncluded(response.data,"scanned_paper_quiz"),
          { root: true }
        )


        commit(types.SCAN_PAPER_LOADING, false, { root: true })

        jsonApii.getIncluded(response.data,"question").forEach( question =>
          commit(types.CREATE_OR_UPDATE_QUESTION, question, { root: true })
        )

        commit(
          types.UPDATE_QUIZZES_TAGS,
          jsonApii.getIncluded(response.data,"quizzes_tag"),
          { root: true }
        )

        commit(types.UPDATE_QUESTIONS_TAGS, jsonApii.getIncluded(response.data, "questions_tag"),  { root: true })
        commit(types.UPDATE_QUESTIONS_TAGS_CATEGORY, jsonApii.getIncluded(response.data, "tags_category"),  { root: true })
        commit(types.UPDATE_CHAT_CHANNELS, jsonApii.getIncluded(response.data, "channel"),  { root: true })
        commit(types.UPDATE_TASK_PROGRESS_MANAGERS, jsonApii.getIncluded(response.data, "task_progress_manager"),  { root: true })
        commit(types.UPDATE_QUIZZES_STATS, jsonApii.getIncluded(response.data, "quizzes_stat"),  { root: true })
        commit(
          types.CREATE_OR_UPDATE_QUIZ_SESSIONS,
          jsonApii.getIncluded(response.data,"quizzes_session"),
          { root: true }
        )
        jsonApii.getIncluded(response.data,"paper_quiz").forEach( paperQuiz =>
          commit(types.UPDATE_PAPER_QUIZ, paperQuiz, { root: true })
        )

        jsonApii.getIncluded(response.data,"time_accommodation_summary").forEach( ta => {
          commit(types.CREATE_OR_UPDATE_TIME_ACCOMMODATION_SUMMARY, ta, { root: true })
        })
        commit(types.UPDATE_QUIZZES_GROUPS, jsonApii.getIncluded(response.data, "quizzes_group"))
        commit(types.UPDATE_SCHOOL_USERS, jsonApii.getIncluded(response.data, "schools_user"))
        commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, jsonApii.getIncluded(response.data, "quizzes_subscription"))


      })
  },
  createQuiz({ commit, rootGetters }, { type, name }) {
    const quiz = {
      quiz_type: type,
      name: name,
    }

    return axios
      .post(
        api.v1.quizzesUrl(),
        {
          quiz,
          schools_user_id: rootGetters.currentSchoolsUser.id,
        })
      .then( response => {
        const quiz = jsonApii.getData(response.data)
        commit(
          types.UPDATE_QUIZZES, [quiz]
        )
        return quiz
      })
  },

  addCheatAttempt(context,quiz) {
    if (quiz?.links) {
      axios.post(quiz.links.add_cheat_attempt, {})
        .then( responce => {
        })
    }
  },
  /**
   *  @deprecated
   */
  retakeQuiz(context,quiz) {},

  toState(context, { quiz, event }) {
    if (quiz) {
      return axios
        .post(quiz.links.handle_event, { event: event })
        .then( response => {
          context.commit(
            types.CREATE_OR_UPDATE_QUIZZ,
            jsonApii.getData(response.data))
        })
    }
  },

  stopQuiz(context, quiz) {
    return context.dispatch("toState",{ quiz: quiz, event: "stop" })
  },
  launchQuiz(context, quiz) {
    return context.dispatch("toState",{ quiz: quiz, event: "publish_and_launch" })
  },
  publishQuiz(context, quiz) {
    return context.dispatch("toState",{ quiz: quiz, event: "publish" })
  },
  startPreviewQuiz(context, quiz) {
    return context.dispatch("toState",{ quiz: quiz, event: "start_preview" })
  },
  stopPreviewQuiz(context, quiz) {
    return context.dispatch("toState",{ quiz: quiz, event: "stop_preview" }).then(() => {
      context.commit(types.UPDATE_SCORES,[], { root: true })

      context.rootGetters.quizInstancesByQuizId(quiz.id).forEach( i =>
        context.commit(types.DELETE_QUIZ_INSTANCE, i, { root: true })
      )
      context.commit(types.FLUSH_ANSWERS)
    })
  },
  resetQuiz(context, quiz) {
    context.dispatch("toState",{ quiz: quiz, event: "reset" })
    context.commit(types.UPDATE_SCORES,[], { root: true })

    context.rootGetters.quizInstancesByQuizId(quiz.id).forEach( i =>
      context.commit(types.DELETE_QUIZ_INSTANCE, i, { root: true })
    )
    context.commit(types.FLUSH_ANSWERS)

  },

  toProctoringState(context, { quiz, event }) {
    if (quiz) {
      axios
        .post(quiz.links.handle_proctoring_event, { event: event })
        .then( response => {
          VuexHelpers.newAppNotification(
            context,
            { message: `Proctoring  ${event}: ` + JSON.stringify(response.data), type: "info" }
          )
        })
    }
  },

  updateQuiz(context, quiz) {
    if (!quiz) { return }

    return axios
      .patch(quiz.links.self, { quiz: quiz })
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_QUIZZ,
          jsonApii.getData(response.data)
        )

        VuexHelpers.newAppNotification(
          context,
          { message: vueI18n.t("quiz.edit.toast.successMessage"), type: "success" }
        )
      })
      .catch(() => {
        VuexHelpers.newAppNotification(
          context,
          { message: vueI18n.t("quiz.edit.toast.errorMessage"), type: "error" }
        )
      }
      )
  },
  destroyQuiz(context, quiz) {
    if (quiz) {
      return axios.delete(api.rootUrl() + quiz.links.self)
        .then( () => {
          context.commit(types.DELETE_QUIZ, quiz)
        }).catch(function (error) {
          console.log(error)
        })
    }
  },
  duplicateQuiz({ commit }, { quiz, new_name }) {
    return axios
      .post(quiz?.links?.duplicate, { new_name })
      .then( response => {
        commit(types.CREATE_OR_UPDATE_QUIZZ, jsonApii.getData(response.data))
        commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, jsonApii.getIncluded(response.data, "quizzes_subscription"))
        commit(types.UPDATE_SCHOOL_USERS, jsonApii.getIncluded(response.data, "schools_user"))
      })
      .catch(error => {
        console.log(error)
      })
  },

  importQuiz(context, { quiz, data, format, file } = { data:null, format:"evalmee", file:null }) {
    context.commit(types.UPDATE_QUIZ_DRAFT_STATE, { status: "importing", quizId: quiz.id }, { root: true })

    return axios
      .post(quiz?.links?.import, { import:{ data, format, file } })
      .then(response => {
        // context.commit(types.UPDATE_QUIZ_DRAFT_STATE, {status: 'loaded'})
        context.commit(types.CREATE_OR_UPDATE_QUIZZ, jsonApii.getData(response.data))
        context.dispatch("fetchQuizDraft", quiz.id)
      })
      .catch(error => {
        if (error.response?.status == 500) {
          VuexHelpers.newAppNotification(
            context,
            { message: "Erreur lors de l'import de l'examen : " + error, type: "error" }
          )
        } else {
          throw error
        }
      })
  },
  sendGradetoStudent(context, { quiz, studentId }) {
    if (!quiz) {return () => {}}

    return axios
      .post(quiz.links.send_scores_to_students, { user_ids: studentId }  )
      .then(() => {
        VuexHelpers.newAppNotification(
          context,
          { message: vueI18n.t("evalmee.home.teacher.quizShareGrades.alert.gradeSent"), type: "success" }
        )
      })
      .catch((error) =>  {
        VuexHelpers.newAppNotification(
          context,
          { message: vueI18n.t("exam.edit.toast.errorMessage") + error, type: "error" }
        )
      })
  },
  sendGradesToStudents(context, quiz) {
    if (quiz) {
      axios
        .post(quiz.links.send_scores_to_students)
        .then(response => {
          context.commit(types.CREATE_OR_UPDATE_QUIZZ, jsonApii.getData(response.data))
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },

  sendGradesToTeacher(context, { quiz, email, scoresByTag }) {
    if (quiz) {
      axios
        .get(
          quiz.links.send_scores_to_teacher,
          { params: { email, scores_by_tag: scoresByTag } }
        )
        .then(response => {
          VuexHelpers.newAppNotification(
            context,
            { message: "Notes envoyées par email", type: "success" }
          )
        })
        .catch((error) => {
          VuexHelpers.newAppNotification(
            context,
            { message: "Erreur lors de l'envoi des notes " + error, type: "error" }
          )
        })
    }
  },

  sendArchivedGradesToTeacher(context, { quiz, email, type }) {
    if (quiz) {
      axios
        .get(quiz.links.send_archived_scores_to_teacher + type, { params: { email } })
        .then(response => {
          VuexHelpers.newAppNotification(
            context,
            { message: vueI18n.t("evalmee.home.teacher.quizTeacherScores.export.toast.successMessage"), type: "success" }
          )
        })
        .catch((error) => {
          VuexHelpers.newAppNotification(
            context,
            { message: vueI18n.t("evalmee.home.teacher.quizTeacherScores.export.toast.errorMessage", error), type: "error" }
          )
        })
    }
  },

  //////////////////////////////////////////////////////////////////////////////
  // Paper quiz
  //////////////////////////////////////////////////////////////////////////////
  fetchSubjectsBatch({}, uuid) {
    return axios
      .get(api.v1.subjectsBatch(), { params: { download_token: uuid } })
      .then( response => {
        return response.data.data
      })
  },
  importPaperQuiz(context, { quiz, file }) {
    axios
      .post(
        quiz.links.import_scanned_quizzes,
        { params: { file: file } }
      )
      .then( responce => {})
  },
  generatePaperQuiz(context, quiz) {
    axios
      .post(quiz.links.generate_paper)
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_QUIZZ,
          jsonApii.getData(response.data)
        )
      })
  },
  cancelGenerationPaperQuiz(context, quiz) {
    axios
      .post(quiz.links.cancel_paper_generation)
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_QUIZZ,
          jsonApii.getData(response.data)
        )
      })
  },
  resetPaperQuiz(context, quiz) {
    axios
      .post(quiz.links.paper_quiz_reset)
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_QUIZZ,
          jsonApii.getData(response.data)
        )
      })
  },
  updatePaperQuiz(context, quiz) {
    return axios
      .patch(
        quiz.links.paper_quiz,
        { paper_quiz: quiz.paper_quiz }
      )
      .then( response => {
        context.commit(
          types.CREATE_OR_UPDATE_QUIZZ,
          jsonApii.getData(response.data)
        )
        VuexHelpers.newAppNotification(
          context,
          { message: vueI18n.t("quiz.edit.toast.successMessage"), type: "success" }
        )
      }).catch(() => {
        VuexHelpers.newAppNotification(
          context,
          { message: vueI18n.t("quiz.edit.toast.errorMessage"), type: "error" }
        )
      }
      )
  },

  sendInstructionsToStudents(context, { quiz, subscriptionIds } = { subscriptionIds: null }) {
    return axios
      .post(
        quiz.links.send_instructions_to_students,
        { subscription_ids: subscriptionIds }
      )
      .then( response => {
        context.commit(types.CREATE_OR_UPDATE_QUIZZ, jsonApii.getData(response.data))
      })
  },

  exportStudents(context, { quiz, subscriptionIds } = { subscriptionIds: null }) {
    axios
      .post(
        quiz.links.export_students,
        { subscription_ids: subscriptionIds },
        { responseType: "blob" }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "export.xlsx") //or any other extension
        document.body.appendChild(link)
        link.click()
      })
  },

  /*Scanned peper quizzes */
  deleteScannedPaperQuiz(context, { quiz, scannedPaperQuiz }) {
    axios
      .delete(
        scannedPaperQuiz.links.self
      )
      .then( () => {
        // Remove all scans from store as they will be fetched again
        context.dispatch("flushScanSheetByQuiz", quiz)
        context.dispatch("fetchQuiz",quiz.id)
      })
  },

  /* LTI */
  // TODO: Ensure subscriptions count is updated after this action
  updateLTISubscriptions(context, quiz) {
    return axios.post(quiz.links.update_lti_subscriptions)
  },

  updateLTIGrades(context, quiz) {
    return axios
      .post(quiz.links.update_lti_grades)
      .then(_response => {
        context.dispatch("fetchQuiz",quiz.id)
      })
  },

  flushQuizzes({ commit }) {
    commit(types.FLUSH_QUIZZES)
  },
}

// mutations
const mutations = {
  [types.UPDATE_QUIZZES](state, quizzes){
    quizzes.forEach( quiz => {
      VuexHelpers.createOrUpdate(state.quizzes, quiz)
    })
  },
  [types.UPDATE_QUIZZES_COUNT](state, meta){
    state.quizzesCount = meta?.total || 0
  },
  [types.UPDATE_QUIZZES_OWNERS](state, users){
    state.owners = users
  },

  [types.DELETE_QUIZ]({ quizzes }, quiz) {
    const index = quizzes.findIndex(e => e.id === quiz.id)
    quizzes.splice(index, 1)
  },

  [types.FLUSH_QUIZZES](state) {
    state.quizzes = []
  },

  [types.CREATE_OR_UPDATE_QUIZZ]({ quizzes }, quiz){
    VuexHelpers.createOrUpdate(quizzes, quiz)
  },
  [types.UPDATE_ANSWER_COUNT](state, [quiz, answerCount]){
    quiz.current_question.answer_count = answerCount
  },
  [types.CREATE_ANSWER](state, answer){
    state.answers.push(answer)
  },
  [types.CREATE_ANSWERS](state, answers){
    state.answers = answers
  },
  [types.CREATE_OR_UPDATE_ANSWER](state, answer){
    VuexHelpers.createOrUpdate(state.answers, answer)
  },
  [types.QUIZZES_LOADING](state, status){
    state.quizzesLoading = status
  },  [types.CURRENT_CORRECTION_LOADING](state, status){
    state.currentCorrectionLoading = status
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
