import VueI18n from "vue-i18n"
import Vue from "vue"

import i18nFileFr from "../i18n/fr.yml"
import i18nFileEn from "../i18n/en.yml"

Vue.use(VueI18n)

// Create VueI18n instance with options
export default new VueI18n({
  locale: navigator.language.substring(0, 2), // set locale
  fallbackLocale: "fr",
  messages: {
    fr: i18nFileFr,
    en: i18nFileEn,
  },
})

